// src/pages/events/ReviewListingPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EventHeader } from '../../components/events/review/EventHeader';
import { FeeBreakdown } from '../../components/events/review/FeeBreakdown';
import { FeesInfo } from '../../components/events/review/FeesInfo';
import Loader from '../../components/common/Loader';
import { apiConfig, getFetchConfig } from '../../config/api';
import { useUserListings } from '../../context/UserListingsContext';

const ReviewListingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addUserListing } = useUserListings();
  const [isFeesExpanded, setIsFeesExpanded] = useState(false);
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  
  const listPrice = parseFloat(location.state?.price || 0);
  const transactionFee = listPrice - listPrice;
  const potentialPayout = listPrice - transactionFee;

  useEffect(() => {
    const checkForExistingListing = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        // Check if user already has a listing for this event
        const listingsResponse = await fetch(
          apiConfig.listings.getForEvent(location.state.eventId),
          getFetchConfig(token)
        );

        if (!listingsResponse.ok) {
          throw new Error('Failed to check listings');
        }

        const { listings } = await listingsResponse.json();

        // Get user profile to check listing ownership
        const profileResponse = await fetch(
          apiConfig.user.profile,
          getFetchConfig(token)
        );

        if (!profileResponse.ok) {
          throw new Error('Failed to get user profile');
        }

        const userData = await profileResponse.json();

        // Check if user has an active listing
        const existingListing = listings.find(
          listing => listing.sellerId === userData._id && listing.status === 'active'
        );

        if (existingListing) {
          // Redirect to listing success page if user already has a listing
          navigate(`/event/${location.state.eventId}/listing-success`, {
            state: {
              price: existingListing.price,
              eventId: location.state.eventId,
              listingId: existingListing._id
            },
            replace: true
          });
          return;
        }

        // Fetch event details
        const eventResponse = await fetch(
          apiConfig.events.detail(location.state.eventId),
          getFetchConfig(token)
        );

        if (!eventResponse.ok) {
          throw new Error('Failed to fetch event');
        }

        const eventData = await eventResponse.json();
        setEvent(eventData);
      } catch (error) {
        console.error('Error:', error);
        setError('Failed to load event details');
      } finally {
        setIsLoading(false);
      }
    };

    if (location.state?.eventId) {
      checkForExistingListing();
    } else {
      navigate('/browse');
    }
  }, [location.state?.eventId, navigate]);

  // In ReviewListingPage.jsx
const handleCreateListing = async () => {
  try {
    setIsSubmitting(true);
    setError('');

    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    const response = await fetch(apiConfig.listings.create, {
      method: 'POST',
      ...getFetchConfig(token),
      body: JSON.stringify({
        eventId: location.state.eventId,
        price: listPrice,
        ticketType: location.state.ticketType,
        section: location.state.section,
        row: location.state.row,
        seat: location.state.seat
      })
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to create listing');
    }

    // Add listing to context
    addUserListing(location.state.eventId, data);

    // Always navigate to listing success page after successful creation
    navigate(`/event/${location.state.eventId}/listing-success`, {
      state: {
        price: listPrice,
        eventId: location.state.eventId,
        listingId: data._id,
        ticketType: location.state.ticketType,
        section: location.state.section,
        row: location.state.row,
        seat: location.state.seat
      },
      replace: true // Use replace to prevent going back to review page
    });
  } catch (error) {
    console.error('Create listing error:', error);
    setError(error.message || 'Failed to create listing');
  } finally {
    setIsSubmitting(false);
  }
};

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-white font-poppins">
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
      <EventHeader 
        event={event}
        ticketInfo={location.state}
        onBack={() => navigate(-1)}
      />

      <div className="flex-1 overflow-y-auto px-6 pt-6 space-y-4">
        {error && (
          <div className="p-4 bg-red-50 text-red-600 rounded-lg text-center">
            {error}
          </div>
        )}

        <FeeBreakdown 
          listPrice={listPrice}
          transactionFee={transactionFee}
          potentialPayout={potentialPayout}
        />

        <div className="rounded-lg border border-[#969696] p-4 flex justify-between items-center">
          <span className="text-black text-base font-semibold">Payout Method:</span>
          <span className="text-[#FF4C03] text-base font-bold">ADD</span>
        </div>

        <FeesInfo 
          isExpanded={isFeesExpanded}
          onToggle={() => setIsFeesExpanded(!isFeesExpanded)}
        />

        <div className="text-center">
          <p className="text-[10px] leading-[14px] mb-2">
            By proceeding, you agree that all ticket info provided is true and accurate. 
            Payment is transferred once the ticket has been verified. Any fraud attempt will be handled as per our{' '}
            <span className="underline cursor-pointer">Terms & Conditions</span>.
          </p>
        </div>

        <button 
          onClick={handleCreateListing}
          disabled={isSubmitting}
          className="w-full py-4 bg-[#FFEFE9] text-[#FF4C03] font-bold text-base rounded-full border-2 border-[#FF4C03] shadow-sm disabled:opacity-50"
        >
          {isSubmitting ? 'Creating Listing...' : 'Create Listing'}
        </button>

        <div className="h-24" />
      </div>
    </div>
  );
};

export default ReviewListingPage;