import React from 'react';
import { Lock, ChevronDown } from 'lucide-react';

export const FeesInfo = ({ isExpanded, onToggle }) => (
  <div className={`space-y-2 mb-4 : ''}`}>
    <button
      onClick={onToggle}
      className="w-full p-4 border border-gray-300 rounded-lg text-left flex justify-between items-center"
    >
      <div className="flex items-center gap-2">
        <Lock size={16} />
        <span className="text-base font-semibold">About our fees</span>
      </div>
      <ChevronDown 
        size={20}
        className={`transition-transform ${isExpanded ? 'rotate-180' : ''}`}
      />
    </button>
    
    {isExpanded && (
      <div className="w-full p-4 border border-gray-300 rounded-lg text-left flex justify-between items-center">
        <div className="text-[14px] leading-[19px] text-center">
  <h3 className="font-semibold mb-2">What is the Transaction Fee?</h3>
  <p>
    We charge a fixed transaction fee of $1 per ticket sale, regardless of the purchase price. This small fee helps cover our server, database, and operational costs, allowing us to maintain a secure and reliable platform for student transactions. Our goal is to provide a safe and accessible marketplace without adding hidden or excessive charges.
  </p>

  <h3 className="font-semibold mt-4 mb-2">What is the Processing Fee?</h3>
  <p>
    The standard processing fee of 2.9% + $0.30 is applied by our payment processor and does not go to SectionU. This fee supports the processing of secure payments and ensures both buyers and sellers are protected. We believe in complete transparency, so you know exactly where each fee goes.
  </p>
</div>
      </div>
    )}
  </div>
);