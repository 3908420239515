// src/components/events/success/views/SellerView/SaleInProgress.jsx
import React from 'react';
import { TransferProgressBar } from '../../TransferProgressBar';

export const SaleInProgress = ({ onInitiateTransfer }) => {
  const handleTransferClick = () => {
    console.log('Transfer button clicked');
    if (typeof onInitiateTransfer === 'function') {
      console.log('Calling onInitiateTransfer');
      onInitiateTransfer();
    } else {
      console.error('onInitiateTransfer is not a function:', onInitiateTransfer);
    }
  };

  return (
    <div className="flex flex-col items-center pt-6">
      <h2 className="text-2xl font-semibold text-center">
        Your Ticket Has Sold!
      </h2>
      
      <p className="text-center pt-4 pb-4 text-sm">
        Please transfer the ticket to complete the sale.
        Your payment will be released after the buyer confirms receipt.
      </p>
     
      <TransferProgressBar status="sale_in_progress" />

      <button
        onClick={handleTransferClick}
        className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full mt-8"
      >
        Initiate Transfer
      </button>
    </div>
  );
};