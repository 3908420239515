// src/pages/events/PurchaseConfirmationPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { EventHeader } from '../../components/events/review/EventHeader';
import successIcon from '../../assets/icons/success-icon.svg';
import { PurchaseSummary } from '../../components/payment/PurchaseSummary';
import Loader from '../../components/common/Loader';
import { apiConfig, getFetchConfig } from '../../config/api';

const PurchaseConfirmationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [listing, setListing] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        const [eventResponse, listingsResponse] = await Promise.all([
          fetch(apiConfig.events.detail(eventId), getFetchConfig(token)),
          fetch(apiConfig.listings.getForEvent(eventId), getFetchConfig(token))
        ]);

        const eventData = await eventResponse.json();
        const listingsData = await listingsResponse.json();
        
        setEvent(eventData);
        
        const targetListing = listingsData.listings.find(l => 
          l._id === location.state?.listingId
        );

        if (!targetListing) {
          navigate(`/event/${eventId}`);
          return;
        }

        setListing(targetListing);

        // Set a timeout to auto-redirect to ListingSuccessPage
        setTimeout(() => {
          navigate(`/event/${eventId}/listing-success`, {
            state: { 
              listingId: targetListing._id,
              eventId,
              status: 'purchase_complete'
            },
            replace: true // Replace the current entry in history
          });
        }, 3000); // 3 second delay

      } catch (error) {
        console.error('Error:', error);
        setError('Failed to load purchase details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [eventId, location.state?.listingId, navigate]);

  if (isLoading) return <Loader />;

  if (error) {
    return (
      <div className="min-h-screen bg-white p-6 flex items-center justify-center">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div style={{ paddingTop: 'env(safe-area-inset-top)' }}>
        <EventHeader 
          event={event} 
          ticketInfo={listing ? {
            ticketType: listing.ticketType,
            section: listing.section,
            row: listing.row,
            seat: listing.seat
          } : undefined}
          onBack={() => navigate('/browse')}
        />
      </div>

      <div className="px-6 py-10 flex flex-col items-center">
        <img 
          src={successIcon} 
          alt="Success" 
          className="w-24 h-24 mb-6"
        />

        <h2 className="text-2xl font-semibold text-center mb-4">
          Purchase Complete
        </h2>

        {listing && <PurchaseSummary price={listing.price} />}
        
        <div className="w-full mb-8">
          <div className="text-center">
            <p className="text-sm text-gray-600">
              The seller will transfer your ticket shortly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseConfirmationPage;