import React from 'react';
import { TransferProgressBar } from '../../TransferProgressBar';

const TransferInitiated = ({ onContinueBrowsing, onViewAlerts }) => {
  return (
    <>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Transfer Initiated.
      </h2>
      <p className="text-center text-sm mb-8">
        The seller has initiated the transfer process. Please check your email for the transfer details.
      </p>
      <TransferProgressBar status="transfer_initiated" />
      <div className="w-full space-y-4 mt-8">
        <button
          onClick={onContinueBrowsing}
          className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full"
        >
          Continue Browsing
        </button>
        <button
          onClick={onViewAlerts}
          className="w-full py-4 bg-[#FFE9DF] text-[#FF4C03] font-bold text-base rounded-full border-2 border-[#FF4C03]"
        >
          Message Seller
        </button>
      </div>
    </>
  );
};

export default TransferInitiated;