// src/pages/auth/VerificationPage.jsx
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/icons/logo.svg'; // Importing the logo
import { apiConfig } from '../../config/api';

const VerificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const inputRefs = useRef([]);
  
  // Get email from navigation state or localStorage
  const email = location.state?.email || localStorage.getItem('verifyEmail');

  useEffect(() => {
    const storedEmail = localStorage.getItem('verifyEmail');
    if (!email && !storedEmail) {
      // If no email is found and there's nothing in localStorage, redirect back to signup
      navigate('/signup');
    } else if (!email && storedEmail) {
      // Restore email from localStorage
      setEmail(storedEmail);
    }
  
    // Focus first input on mount
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [email, navigate]);

  const handleResendCode = async () => {
    if (resendDisabled) return;
    
    setIsLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${apiConfig.auth.signup}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          resend: true
        }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to resend code');
      }

      // Start cooldown timer
      setResendDisabled(true);
      setResendTimer(30);
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

    } catch (error) {
      setError(error.message || 'Failed to resend verification code');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify = async () => {
    const verificationCode = code.join('');
    if (verificationCode.length !== 6) {
      setError('Please enter the complete verification code');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(apiConfig.auth.verify, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          code: verificationCode
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Verification failed');
      }

      // Store the auth token
      localStorage.setItem('token', data.token);
      localStorage.removeItem('verifyEmail'); // Clean up
      
      // Navigate to success page
      navigate('/verification-success');
    } catch (error) {
      setError(error.message || 'Invalid verification code');
      // Clear code inputs on error
      setCode(['', '', '', '', '', '']);
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInput = (index, value) => {
    // Only allow numbers
    if (!/^\d*$/.test(value)) return;
    
    if (value.length > 1) {
      // Handle paste of full code
      const digits = value.split('').slice(0, 6);
      setCode(prevCode => {
        const newCode = [...prevCode];
        digits.forEach((digit, i) => {
          if (i < 6) newCode[i] = digit;
        });
        return newCode;
      });
      // Focus last input or next empty input
      const nextEmptyIndex = digits.length < 6 ? digits.length : 5;
      inputRefs.current[nextEmptyIndex]?.focus();
    } else {
      // Handle single digit input
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      
      // Auto-advance to next input
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }

    // If all digits are filled, verify automatically
    const allDigitsFilled = code.every(digit => digit !== '') && value;
    if (allDigitsFilled) {
      handleVerify();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      if (!code[index] && index > 0) {
        // Move to previous input on backspace if current input is empty
        const newCode = [...code];
        newCode[index - 1] = '';
        setCode(newCode);
        inputRefs.current[index - 1].focus();
      } else {
        // Clear current input
        const newCode = [...code];
        newCode[index] = '';
        setCode(newCode);
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <div className="fixed inset-0 bg-white flex flex-col font-['Poppins']">
      {/* Status Bar Space */}
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
      {/* Header */}
      <div className="flex justify-between items-center px-6 py-4">
        <div className="w-14 h-10 relative">
          {/* Logo */}
      <div className="flex justify-left mb-12">
        <img src={logo} alt="Logo" className="w-14 h-auto" /> {/* Using the SVG logo */}
      </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 px-6 flex flex-col">
        <div className="mt-14 space-y-8">
          <h1 className="text-[29.60px] font-bold text-[#181619] leading-[26.31px]">
            Verify your email.
          </h1>
          <div className="space-y-2">
            <p className="text-[16.44px] text-[#181619] leading-[26.31px]">
              Enter the 6 digit code sent to {email}
            </p>
            <button
              onClick={handleResendCode}
              disabled={resendDisabled || isLoading}
              className="text-[14px] text-[#ff651e] font-medium disabled:opacity-50"
            >
              {resendDisabled 
                ? `Resend code in ${resendTimer}s` 
                : "Didn't receive code? Resend"}
            </button>
          </div>
        </div>

        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-600 rounded-lg text-center">
            {error}
          </div>
        )}

        {/* Verification Code Input */}
        <div className="mt-10 flex justify-between">
          {code.map((digit, index) => (
            <input
              key={index}
              ref={el => inputRefs.current[index] = el}
              type="text"
              inputMode="numeric"
              maxLength={6}
              value={digit}
              onChange={(e) => handleInput(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="w-12 h-[57.01px] text-3xl font-light text-center border-[1.5px] border-[#898989] rounded-[7.67px] focus:outline-none focus:border-[#ff651e]"
              disabled={isLoading}
            />
          ))}
        </div>
      </div>

      {/* Verify Button */}
      <div 
        className="p-6" 
        style={{ paddingBottom: 'max(env(safe-area-inset-bottom) + 24px, 24px)' }}
      >
        <button
          onClick={handleVerify}
          disabled={isLoading || code.some(digit => digit === '')}
          className="w-full h-[60px] bg-[#ff651e] text-white font-bold text-[17.54px] rounded-full disabled:opacity-50"
        >
          {isLoading ? 'Verifying...' : 'Verify'}
        </button>
      </div>
    </div>
  );
};

export default VerificationPage;