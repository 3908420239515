// src/pages/auth/SignupPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import Loader from '../../components/common/Loader';
import logo from '../../assets/icons/logo.svg';
import { isValidSchoolEmail } from '../../domains/domains';
import { apiConfig, isDevelopment } from '../../config/api';

const SignupPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Check token on mount to prevent unwanted access
  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('SignupPage Mount:', {
      hasToken: !!token,
      pathname: location.pathname
    });

    if (token) {
      navigate('/browse', { replace: true });
    }
  }, [navigate, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    console.log('Signup attempt:', {
      email: formData.email,
      hasPassword: !!formData.password
    });

    // Validate email domain
    if (!isValidSchoolEmail(formData.email)) {
      setIsLoading(false);
      navigate('/unsupported-school');
      return;
    }

    try {
      const emailDomain = formData.email.split('@')[1];
      const school = emailDomain === 'iu.edu' ? 'Indiana University' : 'Unknown School';

      const response = await fetch(apiConfig.auth.signup, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, school }),
        credentials: isDevelopment ? 'same-origin' : 'include'
      });

      const data = await response.json();
      console.log('Signup response:', {
        status: response.status,
        hasVerificationCode: !!data.verificationCode
      });

      if (!response.ok) {
        throw new Error(data.message || 'Signup failed');
      }

      // Store email for verification page
      localStorage.setItem('verifyEmail', formData.email);
      
      // Navigate to verification page
      navigate('/verify', { 
        state: { 
          email: formData.email,
          verificationCode: data.verificationCode // Only in development
        },
        replace: true
      });
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  // Prevent unwanted redirects
  const handleNavigation = (path) => {
    console.log('Navigation requested:', { path });
    navigate(path);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="fixed inset-0 bg-white flex flex-col font-['Poppins']">
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
      <div className="flex justify-between items-center px-6 py-4">
        <div className="w-14 h-10 relative">
          <div className="flex justify-left mb-12">
            <img 
              src={logo} 
              alt="Logo" 
              className="w-14 h-auto"
              onClick={() => handleNavigation('/')}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <button 
          className="text-[#ff651e] font-bold text-[17.73px]"
          onClick={() => handleNavigation('/login')}
        >
          Log in
        </button>
      </div>

      {/* Main Content */}
      <div className="flex-1 px-6">
        <div className="mt-16 space-y-3.5">
          <h1 className="text-[27.70px] font-bold text-[#181619] leading-[26.59px]">
            Welcome to SectionU
          </h1>
          <p className="text-[17.73px] text-[#181619] leading-[26.59px]">
            Buy, sell, or exchange event tickets with ease in the industry's first safe ticket exchange marketplace for students.
          </p>
        </div>

        {error && (
          <div className="mt-4 p-3 bg-red-100 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="mt-[42px] space-y-[18px]">
          <input
            type="text"
            placeholder="John"
            value={formData.firstName}
            onChange={(e) => setFormData({...formData, firstName: e.target.value})}
            className="w-full px-[26.59px] py-[17.73px] rounded-[8.86px] border border-[#E1E1E1] text-[17.73px] text-[#7D7D7D] leading-[26.59px] focus:outline-none focus:border-[#ff651e]"
            required
          />
          <input
            type="text"
            placeholder="Appleseed"
            value={formData.lastName}
            onChange={(e) => setFormData({...formData, lastName: e.target.value})}
            className="w-full px-[26.59px] py-[17.73px] rounded-[8.86px] border border-[#E1E1E1] text-[17.73px] text-[#7D7D7D] leading-[26.59px] focus:outline-none focus:border-[#ff651e]"
            required
          />
          <input
            type="email"
            placeholder="japples@iu.edu"
            value={formData.email}
            onChange={(e) => setFormData({...formData, email: e.target.value})}
            className="w-full px-[26.59px] py-[17.73px] rounded-[8.86px] border border-[#E1E1E1] text-[17.73px] text-[#7D7D7D] leading-[26.59px] focus:outline-none focus:border-[#ff651e]"
            required
          />
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={formData.password}
            onChange={(e) => setFormData({...formData, password: e.target.value})}
            className="w-full px-[26.59px] py-[17.73px] rounded-[8.86px] border border-[#E1E1E1] text-[17.73px] text-[#7D7D7D] leading-[26.59px] focus:outline-none focus:border-[#ff651e]"
            required
            
          />
           
          {/* Continue Button */}
          <div className="pt-4">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full h-[60px] bg-[#ff651e] text-white font-bold text-[17.73px] rounded-full disabled:opacity-50"
            >
              {isLoading ? 'Creating Account...' : 'Continue'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;