// src/pages/payment/PaymentConfirmationHandler.jsx
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Loader from '../../components/common/Loader';
import { apiConfig } from '../../config/api';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const PaymentConfirmationContent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const handlePayment = async () => {
      try {
        // Log the complete URL and all params
        console.log('Current URL:', window.location.href);
        
        // Get params from both URL and hash
        const urlParams = new URLSearchParams(window.location.search);
        const hashParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
        
        console.log('URL Parameters:', Object.fromEntries(urlParams.entries()));
        console.log('Hash Parameters:', Object.fromEntries(hashParams.entries()));

        const stripe = await stripePromise;
        if (!stripe) {
          throw new Error('Failed to load Stripe');
        }

        // Try to get client secret from both places
        const clientSecret = urlParams.get('payment_intent_client_secret') || hashParams.get('payment_intent_client_secret');
        const paymentIntentId = urlParams.get('payment_intent') || hashParams.get('payment_intent');
        const eventId = urlParams.get('eventId') || hashParams.get('eventId');
        const listingId = urlParams.get('listingId') || hashParams.get('listingId');

        console.log('Retrieved from URLs:', { 
          clientSecret: clientSecret ? 'exists' : 'missing',
          paymentIntentId: paymentIntentId ? 'exists' : 'missing',
          eventId,
          listingId
        });

        if (!clientSecret || !paymentIntentId) {
          console.error('Missing payment parameters');
          navigate('/browse');
          return;
        }

        console.log('Retrieving payment intent...');
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        
        if (!paymentIntent) {
          throw new Error('Payment intent not found');
        }

        console.log('Payment intent details:', {
          id: paymentIntent.id,
          status: paymentIntent.status,
          metadata: paymentIntent.metadata,
          rawMetadata: JSON.stringify(paymentIntent.metadata),
          metadataKeys: Object.keys(paymentIntent.metadata || {})
        });

        if (paymentIntent.status === 'requires_capture') {
          try {
            const token = localStorage.getItem('token');
            const finalEventId = eventId;
            const finalListingId = listingId;

            if (!finalEventId || !finalListingId) {
              throw new Error('Missing event or listing details');
            }

            // Update listing status using proper API endpoint
            console.log('Updating listing status...');
            const response = await fetch(apiConfig.listings.updateStatus(finalListingId), {
              method: 'PATCH',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                status: 'sale_in_progress',
                paymentIntentId: paymentIntent.id
              })
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || 'Failed to update listing status');
            }

            console.log('Navigating to confirmation page:', { finalEventId, finalListingId });
            navigate(`/event/${finalEventId}/purchase-confirmation`, {
              state: { 
                listingId: finalListingId,
                paymentIntentId: paymentIntent.id
              },
              replace: true
            });
          } catch (error) {
            console.error('Payment confirmation error:', error);
            setError(`Payment confirmation failed: ${error.message}. Please contact support.`);
            
            // Still navigate to avoid user being stuck
            navigate(`/event/${eventId}/purchase-confirmation`, {
              state: { 
                listingId,
                paymentIntentId: paymentIntent.id,
                error: error.message
              },
              replace: true
            });
          }
        } else if (paymentIntent.status === 'succeeded') {
          setError('Payment was captured prematurely. Please contact support.');
        } else {
          throw new Error(`Unexpected payment status: ${paymentIntent.status}`);
        }
      } catch (error) {
        console.error('Payment confirmation error:', error);
        setError(error.message || 'Failed to process payment confirmation');
      }
    };

    handlePayment();
  }, [navigate, searchParams, location]);

  if (error) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white p-6">
        <div className="text-center">
          <h2 className="text-xl font-bold text-red-600 mb-4">Payment Error</h2>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => navigate('/browse')}
            className="px-4 py-2 bg-primary text-white rounded-full"
          >
            Return to Browse
          </button>
        </div>
      </div>
    );
  }

  return <Loader />;
};

const PaymentConfirmationHandler = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentConfirmationContent />
    </Elements>
  );
};

export default PaymentConfirmationHandler;