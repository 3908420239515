import { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

const BACKEND_URL = 'https://sectionu-backend.onrender.com';

export const PaymentForm = ({ amount, eventId, listingId, onPaymentError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setErrorMessage('');

    try {
      console.log('Starting payment process with:', { amount, eventId, listingId });

      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }

      // Create payment intent with metadata
      const response = await fetch(`${BACKEND_URL}/api/payments/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
          amount,
          eventId,
          listingId,
          
          
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Payment intent creation failed:', errorData);
        throw new Error('Failed to create payment intent');
      }

      const { clientSecret } = await response.json();
      console.log('Payment intent created, confirming payment...');

      // Include return state in the URL
      const returnUrl = new URL(window.location.origin + '/#/payment-confirmation');
      returnUrl.searchParams.append('eventId', eventId);
      returnUrl.searchParams.append('listingId', listingId);

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl.toString(),
          payment_method_data: {
            billing_details: {
              name: 'Manual Payment'
            }
          }
        }
      });

      if (error) {
        throw error;
      }

    } catch (error) {
      console.error('Payment error:', error);
      setErrorMessage(error.message || 'Payment failed');
      onPaymentError?.(error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full space-y-4">
      <PaymentElement />
      
      {errorMessage && (
        <div className="p-4 bg-red-50 text-red-600 rounded-lg text-sm">
          {errorMessage}
        </div>
      )}

      <button
        type="submit"
        disabled={isProcessing || !stripe}
        className="w-full py-4 bg-primary text-white font-bold text-base rounded-full disabled:opacity-50"
      >
        {isProcessing ? 'Processing...' : `Place Order`}
      </button>
    </form>
  );
};

export default PaymentForm;