import React from 'react';
import { InputField } from './InputField';
import { PriceInput } from './PriceInput';

export const ListingForm = ({ ticketType, formData, onChange }) => {
  return (
    <div className="space-y-4">
      {ticketType === 'assigned' && (
        <>
          <InputField
            label="Section"
            value={formData.section}
            onChange={(value) => onChange('section', value)}
            placeholder="Enter section number"
          />
          <InputField
            label="Row"
            value={formData.row}
            onChange={(value) => onChange('row', value)}
            placeholder="Enter row number"
            numeric
          />
          <InputField
            label="Seat"
            value={formData.seat}
            onChange={(value) => onChange('seat', value)}
            placeholder="Enter seat number"
            numeric
          />
        </>
      )}
      
      <PriceInput
        value={formData.price}
        onChange={(value) => onChange('price', value)}
        placeholder="Enter asking price"
      />
    </div>
  );
};