import React from 'react';
import { ChevronLeft } from 'lucide-react';

export const EventHeader = ({ 
  event, 
  selectedTab, 
  hasMultipleTypes, 
  onTabChange, 
  onBack 
}) => {
  const formatDate = (date) => {
    if (!date || date === "TBD") return "TBD";
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (time) => {
    if (!time || time === "TBD") return "TBD";
    try {
      const [hours, minutes] = time.split(":");
      const timeObj = new Date();
      timeObj.setHours(parseInt(hours), parseInt(minutes));
      return timeObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true
      });
    } catch (error) {
      console.error("Time formatting error:", error);
      return "Invalid Time";
    }
  };

  return (
    <div className="bg-white">
      {/* Title Bar */}
      <div className="flex items-center px-6 py-4 relative">
        <button onClick={onBack} className="absolute left-6 z-10">
          <ChevronLeft size={24} />
        </button>
        <div className="text-center flex-1">
          <h1 className="text-[17px] font-bold">
            {event?.title?.split(' at ')[0]}
            <span className="text-[#999999]"> at </span>
            {event?.title?.split(' at ')[1]}
          </h1>
          <p className="text-[14px] font-medium text-black">
            {event?.date ? formatDate(event.date) : "TBD"}
            {event?.time ? ` | ${formatTime(event.time)}` : ""}
          </p>
        </div>
      </div>

      {/* Ticket Type Display - Always show, even with single type */}
      <div className="px-6">
        <div className={`flex ${hasMultipleTypes ? 'justify-center gap-8' : 'justify-center'}`}>
          {hasMultipleTypes ? (
            <>
              <button
                onClick={() => onTabChange('general')}
                className={`pb-4 ${
                  selectedTab === 'general'
                    ? 'border-b-2 border-black text-black'
                    : 'text-[#909090]'
                } text-[13.13px] font-medium`}
              >
                General Admission
              </button>
              <button
                onClick={() => onTabChange('assigned')}
                className={`pb-4 ${
                  selectedTab === 'assigned'
                    ? 'border-b-2 border-black text-black'
                    : 'text-[#909090]'
                } text-[13.13px] font-medium`}
              >
                Assigned Seating
              </button>
            </>
          ) : (
            <div className="pb-4 text-black text-[13.13px] font-medium">
              {selectedTab === 'general' ? 'General Admission' : 'Assigned Seating'}
            </div>
          )}
        </div>
      </div>
      <div className="border-b border-black" />
    </div>
  );
};