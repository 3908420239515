import { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/logo-white.svg';
import notwhite from '../../assets/icons/logo.svg'; // Importing the logo


const OnboardingPage = () => {
  const [showCard, setShowCard] = useState(false);
  const navigate = useNavigate();

  // Spring animation for sliding card
  const [style, api] = useSpring(() => ({
    y: '100%',
    config: { tension: 200, friction: 30 },
  }));

  // Handle the 'Learn more' click
  const handleLearnMoreClick = () => {
    setShowCard(true);
    api.start({ y: '0%' });
  };

  // Handle closing the card with animation
  const handleCloseCard = () => {
    api.start({ y: '100%' }).then(() => setShowCard(false));
  };

  // Gesture to handle touch drag for closing via the handle
  const bind = useDrag(
    ({ last, movement: [, my], cancel }) => {
      if (my < 0) {
        cancel(); // Prevent upward drag
      } else if (my > 0) {
        // Only allow downward movement
        api.start({ y: my });
      }
      if (last) {
        if (my > 100) {
          // Close card if dragged down sufficiently
          handleCloseCard();
        } else {
          // Snap back to original position
          api.start({ y: '0%' });
        }
      }
    },
    { from: () => [0, style.y.get()], filterTaps: true }
  );

  return (
    <div
      className="fixed inset-0 flex flex-col items-center justify-between"
      style={{
        background: 'linear-gradient(180deg, #FF4C03 0%, #FF8750 50%, white 74%)',
        paddingTop: 'env(safe-area-inset-top)',
      }}
    >
      {/* Header */}
      <div className="flex flex-col items-center mt-20">
        <img src={logo} alt="SectionU Logo" className="w-16 h-16" />
        <div className="text-center mt-28">
          <h1
            className="text-white font-semibold text-4xl px-6"
            style={{ fontFamily: 'Poppins', lineHeight: '41.27px' }}
          >
            <span>The Secure</span><br />
            <span>Platform to Trade</span><br />
            <span>Student Tickets.</span>
          </h1>
          <p
            className="text-sm underline mt-2 cursor-pointer"
            style={{ color: '#FAFAFA', fontFamily: 'Poppins', lineHeight: '19px' }}
            onClick={handleLearnMoreClick}
          >
            Learn more
          </p>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex flex-col items-center w-full px-6">
        <button
          className="text-[#FF4C03] font-bold text-base mb-4"
          style={{ fontFamily: 'Poppins', lineHeight: '26.59px' }}
          onClick={() => navigate('/login')}
        >
          Log in
        </button>
        <button
          className="bg-[#FF4C03] text-white font-medium rounded-full w-full py-3"
          style={{
            fontFamily: 'Poppins',
            fontSize: '18px',
            lineHeight: '24px',
          }}
          onClick={() => navigate('/signup')}
        >
          Sign Up
        </button>
        <p
          className="text-xs text-center mt-4"
          style={{ color: '#958C8C', fontFamily: 'Poppins', lineHeight: '19px' }}
        >
          By continuing, you acknowledge and agree to SectionU’s legal terms ⇢
        </p>
      </div>

      {/* Animated Sliding Card */}
      {showCard && (
        <animated.div
          className="fixed inset-x-0 bottom-0 bg-white rounded-t-3xl shadow-lg max-w-md mx-auto overflow-hidden"
          style={{
            ...style,
            touchAction: 'none',
            zIndex: 50,
            height: '80vh',
            maxHeight: '80vh', // Limit the height to 80% of the viewport for better scrolling
          }}
        >
          {/* Handle for Drag */}
          <div
            {...bind()} // Only bind gesture handling to the handle
            className="w-12 h-1 bg-gray-400 rounded-full mx-auto my-2 cursor-pointer"
          ></div>

          <button
            className="absolute top-4 right-4 text-gray-600 text-2xl"
            onClick={handleCloseCard}
          >
            &times;
          </button>

          {/* Content Sections */}
          <div className="mt-10 space-y-8 px-6 overflow-y-auto h-[calc(80vh-50px)]"> {/* Adjusted height for scrolling */}
            {/* What is SectionU? */}
            <div>
              <img src={notwhite} alt="SectionU Logo" className="w-12 h-12" />
              <h2 className="mt-4 text-lg font-semibold">What is SectionU?</h2>
              <p className="mt-2  text-gray-700">
                SectionU is the secure secondary ticket marketplace built exclusively for students.
                Unlike the chaotic group chats and risky honor systems you’ve dealt with before,
                we guarantee successful ticket transfers every single time. Our mission is simple:
                offer a platform that students can trust without getting scammed or overcharged.
              </p>
            </div>

            {/* How it Works */}
            <div>
              <h2 className="text-lg font-semibold">How it Works</h2>
              <ol className="list-decimal ml-4 mt-2 text-gray-700 space-y-2">
                <li>
                  <strong>Browse or List Tickets:</strong> Find tickets for events you care about or list
                  your own in just a few clicks.
                </li>
                <li>
                  <strong>Secure Payment:</strong> Payments are held securely until the ticket transfer is completed.
                </li>
                <li>
                  <strong>Guaranteed Transfers:</strong> Every transaction is fully verified and secure before funds are exchanged.
                </li>
                <li>
                  <strong>Success for All:</strong> Buyers receive their tickets, sellers get paid, and everyone wins.
                </li>
              </ol>
            </div>

            {/* Why Choose Us */}
            <div>
              <h2 className="text-lg font-semibold">Why Choose Us?</h2>
              <ul className="list-disc ml-4 mt-2 text-gray-700 space-y-2">
                <li><strong>$1 Always Fee for Buyers:</strong> No hidden fees, just $1 for buyers.</li>
                <li><strong>Free for Sellers:</strong> Selling tickets should be simple and free.</li>
                <li><strong>Built for Students:</strong> Fair pricing, secure transactions, and a focus on your needs.</li>
              </ul>
            </div>

            {/* Quick FAQ */}
            <div>
              <h2 className="text-lg font-semibold">Quick FAQ</h2>
              <div className="mt-2 space-y-4">
                <div>
                  <strong>Q: How much are fees?</strong>
                  <p>A: We are not here to squeeze you for fees :) Sellers don’t pay anything. For buyers, it's always $1, no matter the ticket price. This allows us to maintain the best available ticket exchange for students, and insure it runs at the highest level. </p>
                </div>
                <div>
                  <strong>Q: Is SectionU really secure?</strong>
                  <p>A: Yes! We guarantee successful transfers and protect every transaction, so you can buy and sell with confidence.</p>
                </div>
                <div>
                  <strong>Q: Why does SectionU only charge $1?</strong>
                  <p>A: Our low fee covers operating costs and reflects our commitment to keeping this platform fair and student-friendly. Unlike alternatives, we’re not here to gouge you—we’re here to serve you.</p>
                </div>
              </div>
            </div>
          </div>
        </animated.div>
      )}
    </div>
  );
};

export default OnboardingPage;