import React from 'react';
import { Lock, ChevronDown } from 'lucide-react';

export const FeesInfo = ({ isExpanded, onToggle }) => (
  <div className={`space-y-2 ${isExpanded ? 'mb-4' : ''}`}>
    <button
      onClick={onToggle}
      className="w-full rounded-lg border border-[#969696] p-4 flex items-center justify-between"
    >
      <div className="flex items-center gap-2">
        <Lock size={16} />
        <span className="text-base font-semibold">About our fees</span>
      </div>
      <ChevronDown 
        size={20}
        className={`transition-transform ${isExpanded ? 'rotate-180' : ''}`}
      />
    </button>
    
    {isExpanded && (
      <div className="p-4 bg-white rounded-lg border border-[#969696]">
        <p className="text-[14px] leading-[19px] text-center">
        It’s completely free to list and sell your tickets here. We’re not about squeezing extra fees or taking a cut—we’re  here to provide a safe, easy, and trusted platform for students to buy and sell tickets with confidence. Payments are held securely until the buyer confirms, ensuring both sides are protected. Our goal is simple: to make the student ticket marketplace fair, accessible, and stress-free, purely for the love of the game.
        </p>
      </div>
    )}
  </div>
);