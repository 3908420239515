// src/components/events/success/views/BuyerView/PurchaseComplete.jsx
import React from 'react';
import successIcon from '../../../../../assets/icons/success-icon.svg';
import { TransferProgressBar } from '../../TransferProgressBar';
import { PurchaseSummary } from '../../../../payment/PurchaseSummary';

const PurchaseComplete = ({ event, listing, onContinueBrowsing, onViewAlerts }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <img src={successIcon} alt="Success" className="w-24 h-24 mb-6" />
      
      <h2 className="text-2xl font-semibold text-center mb-4">
        Purchase Complete
      </h2>

      {/* Using existing PurchaseSummary component */}
      <PurchaseSummary price={listing?.price} />
      
      <div className="w-full mb-8">
        <div className="bg-[#FFE9DF] rounded-lg p-4 text-center">
        </div>
      </div>

      <TransferProgressBar status="sale_in_progress" />

      <div className="w-full space-y-4 mt-8">

      </div>
    </div>
  );
};

export default PurchaseComplete;