import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiConfig } from "../../config/api";
import logo from "../../assets/icons/logo-white.svg";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import Loader from "../../components/common/Loader"; // Importing the Loader component

const ProfilePage = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInfoCard, setShowInfoCard] = useState(false);
  const [style, api] = useSpring(() => ({ y: "100%" })); // Animation control for sliding effect
  const [error, setError] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleShowInfoCard = () => {
    setShowInfoCard(true);
    api.start({ y: "0%" });
  };

  const handleCloseInfoCard = () => {
    api.start({ y: "100%" }).then(() => setShowInfoCard(false));
  };

  const bindInfoCard = useDrag(
    ({ last, movement: [, my], cancel }) => {
      if (my < 0) cancel(); // Prevent upward drag
      if (my > 0) api.start({ y: my });
      if (last) {
        if (my > 100) handleCloseInfoCard();
        else api.start({ y: "0%" });
      }
    },
    { from: () => [0, style.y.get()], filterTaps: true }
  );

  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsLoading(true); // Make sure this is set at the start of fetching
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }

        const response = await fetch(apiConfig.user.profile, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch profile");
        }

        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError("Failed to load profile");
        console.error("Profile fetch error:", err);
      } finally {
        setIsLoading(false); // Set this after loading is complete
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("rememberedEmail");
    navigate("/onboarding");
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(apiConfig.user.delete, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Failed to delete account");
      }

      localStorage.removeItem("token");
      localStorage.removeItem("rememberedEmail");
      navigate("/signup");
    } catch (err) {
      setError(err.message || "Failed to delete account");
      console.error("Delete account error:", err);
    }
  };

  if (isLoading) {
    return <Loader delay={300} />; // Only show if loading takes longer than 300ms
  }

  return (
    <div className="fixed inset-0 flex flex-col bg-white font-['Poppins'] overflow-hidden">
      {/* Fixed Header Section */}
      <div className="relative pt-4 flex-none">
        {/* Orange background */}
        <div className="absolute inset-x-0 top-0 h-[260px] bg-[#FF4C03] rounded-b-[60px]" />

        {/* Status Bar Space and Log Out Button Container */}
        <div className="relative z-20">
          <div
            className="w-full px-6 py-4 flex justify-between items-center"
            style={{ paddingTop: "max(env(safe-area-inset-top), 32px)" }}
          >
            {/* Rating Display */}
            <div className="flex items-center gap-2 bg-white text-[#FF4C03] rounded-full px-4 py-2 shadow-md">
              <span className="text-md">★</span>
              {userData?.totalRatings > 0 ? (
                <span className="text-l font-medium">
                  {userData.averageRating.toFixed(2)}
                </span>
              ) : (
                <span className="text-md">No ratings yet</span>
              )}
            </div>

            {/* Log Out Button */}
            <button
              onClick={handleLogout}
              className="text-white text-[18px] font-[700] leading-[29px]"
            >
              Log Out
            </button>
          </div>
        </div>

        {/* Profile Card */}
        <div className="pt-5 relative z-10 px-6">
          <div className="w-full p-8 bg-white/50 backdrop-blur-md rounded-[20px] border-2 border-white shadow-lg">
            <div className="flex flex-col items-center space-y-3">
              <h1 className="text-[29px] font-semibold text-[#030303] leading-[29px]">
                {userData?.firstName} {userData?.lastName}
              </h1>
              <p className="text-[15px] font-medium text-[#030303] leading-[29px]">
                {userData?.email}
              </p>
              <button className="mt-2 px-7 py-2 bg-[#FF4C03] text-white text-[14.4px] font-medium rounded-[14.4px] shadow-md cursor-default">
                Member Since '
                {new Date(userData?.createdAt)
                  .getFullYear()
                  .toString()
                  .slice(-2)}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto px-6">
        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-600 rounded-lg text-center">
            {error}
          </div>
        )}

        {/* Profile Details */}
        <div className="mt-6 space-y-8 pb-32">
          {/* Full Name */}
          <div className="space-y-2">
            <label className="text-[16px] font-semibold text-[#FF4C03] leading-[29px]">
              Full Name
            </label>
            <div className="pb-2 text-[18px] font-medium text-[#030303]/30 leading-[29px] border-b border-[#E2E2E2]">
              {userData?.firstName} {userData?.lastName}
            </div>
          </div>

          {/* Email */}
          <div className="space-y-2">
            <label className="text-[16px] font-semibold text-[#FF4C03] leading-[29px]">
              Email
            </label>
            <div className="pb-2 text-[18px] font-medium text-[#030303]/30 leading-[29px] border-b border-[#E2E2E2]">
              {userData?.email}
            </div>
          </div>

          {/* School */}
          <div className="space-y-2">
            <label className="text-[16px] font-semibold text-[#FF4C03] leading-[29px]">
              School
            </label>
            <div className="pb-2 text-[18px] font-medium text-[#030303]/30 leading-[29px] border-b border-[#E2E2E2]">
              {userData?.school}
            </div>
          </div>

          <button
            className="text-[13px] font-semibold text-[#8a8a8a]"
            onClick={handleShowInfoCard}
          >
            Why can't I edit my info?
          </button>


          {showInfoCard && (
 <animated.div
 {...bindInfoCard()} // Apply touch gesture handling
 className="fixed inset-x-0 bottom-0 bg-white rounded-t-3xl max-w-md mx-auto overflow-hidden"
 style={{
   ...style,
   touchAction: "none",
   zIndex: 9999, // Ensures it appears above other elements
   height: "30vh",
   maxHeight: "80vh",
   boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.2)", // Custom shadow with more prominence at the top
 }}
>
<button
  className="absolute top-6 right-6 text-gray-800 text-3xl z-50" // Added z-50 for higher z-index
  onClick={handleCloseInfoCard}
>
  &times;
</button>

    <div className="mt-1 space-y-6 px-6 overflow-y-auto h-[calc(80vh-50px)]">
      <h2 className="mt-6 text-lg font-semibold">
        Why can't I edit my info?
      </h2>
      <p className="mt-2 text-gray-700">
  Editing your information is currently restricted for security purposes. If you need to update your details, please contact support for assistance.
</p>
<p className="text-[#FF4C03] font-semibold">
  <a href="mailto:support@sectionu.com" className="no-underline hover:no-underline focus:no-underline">
    support@sectionu.com
  </a>
</p>
    </div>
  </animated.div>
)}

         {/* Payment Methods */}
<div className="space-y-4 mt-6">
  <div
    onClick={() => navigate("/payment-methods")}
    className="p-4 bg-white rounded-lg border border-gray-200 cursor-pointer"
  >
    <div className="flex justify-between items-center">
      <span className="text-[18px] font-medium">Preferred Payment Method</span>
      <span className="text-gray-500">&gt;</span>
    </div>
  </div>

  <div
    onClick={() => navigate("/payout-methods")}
    className="p-4 bg-white rounded-lg border border-gray-200 cursor-pointer"
  >
    <div className="flex justify-between items-center">
      <span className="text-[18px] font-medium">Payout Method</span>
      <span className="text-gray-500">&gt;</span>
    </div>
  </div>
</div>

         {/* Delete Account Section */}
<div className="mt-8">
  {showDeleteConfirm ? (
    <div className="space-y-4">
      <p className="text-center text-[#BA0000] font-medium">
        Are you sure you want to delete your account? This action cannot be undone.
      </p>
      <div className="flex gap-4">
        <button
          onClick={() => setShowDeleteConfirm(false)}
          className="flex-1 py-2 border border-[#BA0000] text-[#BA0000] rounded-lg"
        >
          Cancel
        </button>
        <button
          onClick={handleDeleteAccount}
          className="flex-1 py-2 bg-[#BA0000] text-white rounded-lg"
        >
          Confirm Delete
        </button>
      </div>
    </div>
  ) : (
    <button
      onClick={() => setShowDeleteConfirm(true)}
      className="w-full text-left py-4 px-4 bg-[#FFE6E6] border border-[#BA0000] text-[#BA0000] rounded-lg"
    >
      <span className="text-[#BA0000] text-[16px] font-[600] leading-[29px]">
        Delete my account
      </span>
    </button>
  )}
</div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
