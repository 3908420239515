import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout'; 

import { TicketPurchaseProvider } from './context/TicketPurchaseContext'


// Auth Components
import ProtectedRoute from './components/auth/ProtectedRoute';
import OnboardingPage from './pages/auth/OnboardingPage';
import SignupPage from './pages/auth/SignupPage';
import LoginPage from './pages/auth/LoginPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import UnsupportedSchoolPage from './pages/auth/UnsupportedSchoolPage';
import VerificationPage from './pages/auth/VerificationPage';
import VerificationSuccessPage from './pages/auth/VerificationSuccessPage';

// Main App Pages
import BrowsePage from './pages/tickets/BrowsePage';
import ProfilePage from './pages/profile/ProfilePage';
import AlertsPage from './pages/alerts/AlertsPage';
import ActivityPage from './pages/activity/ActivityPage';

// Event Pages
import EventDetailPage from './pages/events/EventDetailPage';
import ListingFormPage from './pages/events/ListingFormPage';
import ListingSuccessPage from './pages/events/ListingSuccessPage';
import ReviewListingPage from './pages/events/ReviewListingPage';
import BuyPage from './pages/events/BuyPage';
import PurchaseConfirmationPage from './pages/events/PurchaseConfirmationPage';

// Payment Pages
import PaymentConfirmationHandler from './pages/payment/PaymentConfirmationHandler';

// Admin Pages
import AdminRoute from './components/auth/AdminRoute';
import AdminPanel from './pages/admin/AdminPanel';
import AdminLoginPage from './pages/admin/AdminLoginPage';

// Context Provider
import { UserListingsProvider } from './context/UserListingsContext';

// Create a Layout component to handle routing logic
const AppContent = () => {
  const location = useLocation();
  
  // If we're on any admin route, don't show regular app content
  if (location.pathname.startsWith('/admin')) {
    return (
      <Routes>
        <Route path="/admin" element={<AdminLoginPage />} />
        <Route path="/admin/dashboard" element={
          <AdminRoute>
            <AdminPanel />
          </AdminRoute>
        } />
        <Route path="/admin/*" element={<Navigate to="/admin" replace />} />
      </Routes>
    );
  }

  // Regular app routes
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<OnboardingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/verify" element={<VerificationPage />} />
      <Route path="/verification-success" element={<VerificationSuccessPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/unsupported-school" element={<UnsupportedSchoolPage />} />

      {/* Payment Routes */}
      <Route path="/payment-confirmation" element={
        <ProtectedRoute>
          <PaymentConfirmationHandler />
        </ProtectedRoute>
      } />

      {/* Protected Routes */}
      <Route path="/browse" element={
        <ProtectedRoute>
          <BrowsePage />
        </ProtectedRoute>
      } />
      
      <Route path="/profile" element={
        <ProtectedRoute>
          <ProfilePage />
        </ProtectedRoute>
      } />
      
      <Route path="/alerts" element={
        <ProtectedRoute>
          <AlertsPage />
        </ProtectedRoute>
      } />

      <Route path="/activity" element={
        <ProtectedRoute>
         <ActivityPage />
        </ProtectedRoute>
      } />

      {/* Event Routes */}
      <Route path="/event/:eventId" element={
        <ProtectedRoute>
          <EventDetailPage />
        </ProtectedRoute>
      } />
      
      <Route path="/event/:eventId/list" element={
        <ProtectedRoute>
          <ListingFormPage />
        </ProtectedRoute>
      } />

      <Route path="/event/:eventId/review" element={
        <ProtectedRoute>
          <ReviewListingPage />
        </ProtectedRoute>
      } />

      <Route path="/event/:eventId/listing-success" element={
        <ProtectedRoute>
          <ListingSuccessPage />
        </ProtectedRoute>
      } />

      <Route path="/event/:eventId/buy" element={
        <ProtectedRoute>
          <BuyPage />
        </ProtectedRoute>
      } />

      <Route path="/event/:eventId/purchase-confirmation" element={
        <ProtectedRoute>
          <PurchaseConfirmationPage />
        </ProtectedRoute>
      } />

      {/* Catch all - redirect to home */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

function App() {
  return (
    <Router>
       <TicketPurchaseProvider>
         <UserListingsProvider>
           <AppLayout>
             <AppContent />
            </AppLayout>
          </UserListingsProvider>
        </TicketPurchaseProvider>
    </Router>
  );
}


export default App;