// src/context/UserListingsContext.jsx
import React, { createContext, useState, useContext, useCallback } from 'react';
import { apiConfig, getFetchConfig } from '../config/api';

const UserListingsContext = createContext(null);

export const UserListingsProvider = ({ children }) => {
  const [userListings, setUserListings] = useState({});

  const checkUserListing = useCallback(async (eventId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return null;

      const response = await fetch(
        apiConfig.listings.getForEvent(eventId),
        getFetchConfig(token)
      );

      if (!response.ok) return null;

      const { listings } = await response.json();
      
      // Get user profile to get ID
      const profileResponse = await fetch(
        apiConfig.user.profile,
        getFetchConfig(token)
      );

      if (!profileResponse.ok) return null;

      const userData = await profileResponse.json();
      const userId = userData._id;

      // Find user's active listing
      const userListing = listings.find(
        listing => listing.sellerId === userId && listing.status === 'active'
      );

      // Update context state
      if (userListing) {
        setUserListings(prev => ({
          ...prev,
          [eventId]: userListing
        }));
      }

      return userListing;
    } catch (error) {
      console.error('Error checking user listing:', error);
      return null;
    }
  }, []);

  const addUserListing = (eventId, listing) => {
    setUserListings(prev => ({
      ...prev,
      [eventId]: listing
    }));
  };

  const removeUserListing = (eventId) => {
    setUserListings(prev => {
      const newListings = { ...prev };
      delete newListings[eventId];
      return newListings;
    });
  };

  const value = {
    userListings,
    checkUserListing,
    addUserListing,
    removeUserListing
  };

  return (
    <UserListingsContext.Provider value={value}>
      {children}
    </UserListingsContext.Provider>
  );
};

export const useUserListings = () => {
  const context = useContext(UserListingsContext);
  if (!context) {
    throw new Error('useUserListings must be used within a UserListingsProvider');
  }
  return context;
};

export default UserListingsContext;