// src/components/common/AnimatedEllipsis.jsx
import React, { useState, useEffect } from 'react';

const AnimatedEllipsis = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev === '...') return '';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <span className="inline-block w-[20px]">{dots}</span>;
};

export default AnimatedEllipsis;