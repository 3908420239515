// src/components/events/detail/EventImage.jsx
import React from 'react';
import logo from '../../../assets/icons/logo-white.svg';

export const EventImage = ({ event }) => {
  return (
    <div className="relative">
      <img 
        src={event?.imageUrl}
        alt={event?.title}
        className="w-full h-[166px] object-cover"
      />
      <div 
        className="absolute bottom-0 right-0 w-[105px] h-[100px]"
        style={{
          clipPath: 'polygon(100% 0, 100% 100%, 0 100%)',
          backgroundColor: '#FF4C03'
        }}
      >
        <div className="absolute bottom-2 right-2">
          <img 
            src={logo}
            alt="Logo"
            className="w-[45.22px] h-[43.33px]"
          />
        </div>
      </div>
    </div>
  );
};