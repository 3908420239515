// src/components/events/review/FeeBreakdown.jsx
export const FeeBreakdown = ({ listPrice, transactionFee, potentialPayout }) => (
  <div className="rounded-lg border border-[#969696] p-4 space-y-2">
    <div className="flex justify-between items-center">
      <span className="text-base font-normal">List Price</span>
      <span className="text-[#242424] text-base font-semibold">
        ${listPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </span>
    </div>
    <div className="flex justify-between items-center">
      <span className="text-base font-normal">Fees (None)</span>
      <span className="text-base font-semibold">
        - ${transactionFee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </span>
    </div>
    <div className="flex justify-between items-center">
      <span className="text-lg font-semibold">Payout</span>
      <span className="text-[#15803D] text-lg font-extrabold">
        ${potentialPayout.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </span>
    </div>
  </div>
);