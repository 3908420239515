// src/utils/feeCalculations.js
export const calculateFees = (basePrice) => {
    const transactionFee = 1.00;  // Fixed $1 transaction fee
    
    // Calculate total before fees
    const amountWithTransactionFee = basePrice + transactionFee;
    
    // Calculate Stripe's fee to ensure we net exactly $1
    // Formula: stripeAmount = (baseAmount) / (1 - 0.029) + 0.30
    const totalWithStripe = Math.ceil((amountWithTransactionFee / (1 - 0.0292) + 0.30) * 100) / 100;
    const processingFee = Math.ceil((totalWithStripe - amountWithTransactionFee) * 100) / 100;
  
    const total = Math.ceil((basePrice + transactionFee + processingFee) * 100) / 100;
  
    return {
      purchasePrice: basePrice,
      transactionFee: transactionFee,
      processingFee: processingFee,
      total: total
    };
  };