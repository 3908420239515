// src/pages/auth/ForgotPasswordPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    // TODO: Implement password reset functionality
    setSuccess(true);
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen bg-white font-['Poppins'] p-6">
      {/* Status Bar Space */}
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />

      {/* Logo */}
      <div className="w-14 h-10 relative mb-12">
        <div className="w-[42.85px] h-[35.11px] left-[0.36px] top-[4.89px] absolute bg-[#FF4C03]" />
        <div className="w-[43.75px] h-[31.31px] left-[12.25px] top-0 absolute bg-[#FF4C03]" />
        <div className="w-[25.62px] h-[11.55px] left-0 top-[15.45px] absolute bg-[#FF4C03]" />
      </div>

      <div className="flex flex-col gap-[18px]">
        <h1 className="text-[27.70px] font-bold text-[#181619] leading-[27.70px]">
          Reset Password
        </h1>

        {error && (
          <div className="p-4 bg-red-50 text-red-600 rounded-lg text-center">
            {error}
          </div>
        )}

        {success ? (
          <div className="flex flex-col gap-6">
            <div className="p-4 bg-green-50 text-green-600 rounded-lg text-center">
              If an account exists with this email, you'll receive reset instructions.
            </div>
            <button
              onClick={() => navigate('/login')}
              className="w-full py-[10.96px] bg-[#FF4C03] text-white text-[17.54px] font-bold leading-[26.31px] rounded-[109.63px]"
            >
              Return to Login
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col gap-[33px]">
            <div className="flex flex-col gap-[19px]">
              <input
                type="email"
                placeholder="School Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-[27.70px] py-[18.47px] rounded-[9.23px] border border-[#E1E1E1] text-[18.47px] text-[#7D7D7D] leading-[27.70px] focus:outline-none focus:border-[#FF4C03]"
                required
              />
            </div>

            <div className="flex flex-col gap-[37px] items-center">
              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-[10.96px] bg-[#FF4C03] text-white text-[17.54px] font-bold leading-[26.31px] rounded-[109.63px] disabled:opacity-50"
              >
                {isLoading ? 'Sending...' : 'Reset Password'}
              </button>
              
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="text-[18.47px] text-[#FF4C03] font-bold leading-[27.70px]"
              >
                Back to Login
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;