import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import AnimatedEllipsis from '../../components/common/AnimatedEllipsis';
import Loader from '../../components/common/Loader';
import { apiConfig, getFetchConfig } from '../../config/api';

const ActivityPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);
  const [buyingActivities, setBuyingActivities] = useState([]);
  const [sellingActivities, setSellingActivities] = useState([]);
  const [completedActivities, setCompletedActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingListing, setEditingListing] = useState(null);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [newPrice, setNewPrice] = useState('');

  const fetchActivities = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      // Fetch user's profile to get their ID
      const userResponse = await fetch(apiConfig.user.profile, getFetchConfig(token));
      const userData = await userResponse.json();

      // Fetch all events
      const eventsResponse = await fetch(
        apiConfig.events.list('Indiana University'), 
        getFetchConfig(token)
      );
      const events = await eventsResponse.json();

      // For each event, fetch listings to find user's activities
      const activities = await Promise.all(
        events.map(async (event) => {
          const listingsResponse = await fetch(
            apiConfig.listings.getForEvent(event._id),
            getFetchConfig(token)
          );
          const listingsData = await listingsResponse.json();
          
          return {
            event,
            listings: listingsData.listings || []
          };
        })
      );

      // Filter and organize activities
      const buying = [];
      const selling = [];
      const completed = [];

      activities.forEach(({ event, listings }) => {
        if (!listings) return; // Check if listings exist
      
        listings.forEach(listing => {
          // Skip if listing is invalid
          if (!listing) return;
          
          // First create enriched listing with safe checks
          const enrichedListing = {
            ...listing,
            event,
            activityType: listing.buyerId?._id === userData._id ? 'buying' : 'selling'
          };
        
          // Handle completed/confirmed listings
          if (listing.status === 'confirmed' || listing.status === 'completed') {
            console.log('Found completed listing:', listing);
            completed.push(enrichedListing);
            return;
          }
      
          // Handle buying activities - already using optional chaining
          if (listing.buyerId?._id === userData._id) {
            buying.push(enrichedListing);
          }
      
          // Handle selling activities - add null check for sellerId
          if (listing.sellerId && listing.sellerId._id && listing.sellerId._id === userData._id) {
            // Get all active listings with safe checks
            const allActiveListingsForEvent = listings.filter(l => 
              l && 
              l.status === 'active' && 
              typeof l.price === 'number' // Ensure price exists and is a number
            );
      
            const lowestPriceForEvent = allActiveListingsForEvent.length > 0 
              ? Math.min(...allActiveListingsForEvent.map(l => l.price))
              : (listing.price || 0); // Fallback to 0 if no price
      
            // Safe price comparison
            const isLowestPrice = typeof listing.price === 'number' && 
                                listing.price <= lowestPriceForEvent;
      
            selling.push({
              ...enrichedListing,
              isLowestPrice,
              lowestPriceForEvent
            });
          }
        });
      });
      
      // Add debug logging
      console.log('Activity counts:', {
        buying: buying.length,
        selling: selling.length,
        completed: completed.length
      });

      setBuyingActivities(buying);
      setSellingActivities(selling);
      setCompletedActivities(completed);

      // Set initial active tab based on activities
      if (!activeTab) {  // Only set if not already set
        if (completed.length > 0) {
          setActiveTab('completed');
        } else if (buying.length === 0 && selling.length > 0) {
          setActiveTab('selling');
        } else {
          setActiveTab('buying');
        }
      }

    } catch (error) {
      console.error('Error fetching activities:', error);
      setError('Failed to load activities');
    } finally {
      setIsLoading(false);
    }
  }, [navigate, activeTab]);

  // Initial load
  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  // WebSocket connection
  useEffect(() => {
    const token = localStorage.getItem('token');
    const ws = new WebSocket(`${import.meta.env.VITE_WS_URL || 'ws://localhost:8000'}?token=${token}`);
    
    ws.onopen = () => {
      console.log('WebSocket connected');
    };
    
    ws.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        console.log('WebSocket message received:', message);
        if (message.type === 'LISTING_UPDATE') {
          console.log('Refreshing activities due to listing update');
          fetchActivities();
        }
      } catch (error) {
        console.error('WebSocket message handling error:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [fetchActivities]);

  const handleEditPrice = async (listing) => {
    try {
      const token = localStorage.getItem('token');
      console.log('Updating listing:', listing._id, 'to price:', newPrice);

      const response = await fetch(`/api/listings/${listing._id}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          price: parseFloat(newPrice)
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        throw new Error(errorData.message || 'Failed to update listing price');
      }

      await fetchActivities();
      setShowPriceModal(false);
      setEditingListing(null);
      setNewPrice('');
    } catch (error) {
      console.error('Error updating price:', error);
      setError('Failed to update price');
    }
  };

  const renderActivityItem = (activity) => {
    const getStatusDisplay = () => {
      switch (activity.status) {
        case 'active':
          return (
            <span className="flex items-center">
              Your Listing is Live<AnimatedEllipsis />
            </span>
          );
        case 'sale_in_progress':
          return activeTab === 'selling' ? (
            <span className="flex items-center">
              Sale in Progress<AnimatedEllipsis />
            </span>
          ) : (
            <span className="flex items-center">
              Purchase Confirmed<AnimatedEllipsis />
            </span>
          );
        case 'transfer_initiated':
          return activeTab === 'selling' ? (
            <span className="flex items-center">
              Transfer Initiated<AnimatedEllipsis />
            </span>
          ) : (
            <span className="flex items-center">
              Transfer In Progress<AnimatedEllipsis />
            </span>
          );
        case 'ticket_sent':
          return activeTab === 'selling' ? (
            <span className="flex items-center">
              Ticket Sent<AnimatedEllipsis />
            </span>
          ) : (
            <span className="flex items-center">
              Please Confirm Receipt of Transfer<AnimatedEllipsis />
            </span>
          );
        default:
          return '';
      }
    };

    return (
      <div 
        key={activity._id}
        className="bg-[#FFE5DB] rounded-lg p-4 mb-4"
        onClick={() => navigate(`/event/${activity.event._id}/listing-success`, {
          state: { 
            listingId: activity._id,
            eventId: activity.event._id,
            status: activity.status
          }
        })}
      >
        <div className="flex justify-between items-center">
          <div>
            <span className="text-[#FF4C03] text-xl font-bold">${activity.price}</span>
            <h3 className="text-base font-medium">{activity.event.title}</h3>
            <p className="text-sm text-gray-600">{getStatusDisplay()}</p>
          </div>
          <ChevronRight className="text-gray-400" />
        </div>

        {activeTab === 'selling' && 
         activity.status === 'active' && 
         !activity.isLowestPrice && (
          <div className="mt-4">
            <p className="text-sm text-gray-600 mb-2">You are not the lowest listing</p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setEditingListing(activity);
                setShowPriceModal(true);
                setNewPrice((activity.lowestPriceForEvent - 1).toString());
              }}
              className="w-full py-2 bg-[#FF4C03] text-white rounded-full text-sm"
            >
              Become the Lowest Listing at ${activity.lowestPriceForEvent - 1}
            </button>
          </div>
        )}
      </div>
    );
  };

 const renderCompletedItem = (activity) => {
  // Improved date formatting function to handle `completedAt` date
  const formatDate = (date) => {
    if (!date || isNaN(new Date(date).getTime())) {
      return "Invalid Date";
    }
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <div 
      key={activity._id}
      className="bg-gray-50 rounded-lg p-4 mb-4"
      onClick={() => navigate(`/event/${activity.event._id}/listing-success`, {
        state: { 
          listingId: activity._id,
          eventId: activity.event._id,
          status: activity.status,
        }
      })}
    >
      <div className="flex justify-between items-center">
        <div>
          <span className="text-green-600 text-xl font-bold">${activity.price}</span>
          <h3 className="text-base font-medium">{activity.event.title}</h3>
          <p className="text-sm text-gray-600">
            {activity.activityType === 'buying' ? 'Purchased' : 'Sold'} on {formatDate(activity.completedAt)}
          </p>
        </div>
        <ChevronRight className="text-gray-400" />
      </div>
    </div>
  );
};

  if (isLoading) return <Loader />;

  return (
    <div className="min-h-screen bg-white">
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
      <div className="px-6 py-4">
        <h1 className="text-2xl font-bold mb-4">Activity</h1>
        
        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab('buying')}
            className={`${
              activeTab === 'buying'
                ? 'text-black border-b-2 border-black'
                : 'text-gray-400'
            } pb-2`}
          >
            Buying {buyingActivities.length > 0 && `(${buyingActivities.length})`}
          </button>
          <button
            onClick={() => setActiveTab('selling')}
            className={`${
              activeTab === 'selling'
                ? 'text-black border-b-2 border-black'
                : 'text-gray-400'
            } pb-2`}
          >
            Selling {sellingActivities.length > 0 && `(${sellingActivities.length})`}
          </button>
          <button
            onClick={() => setActiveTab('completed')}
            className={`${
              activeTab === 'completed'
                ? 'text-black border-b-2 border-black'
                : 'text-gray-400'
            } pb-2`}
          >
            Completed {completedActivities.length > 0 && `(${completedActivities.length})`}
          </button>
        </div>

        {error && (
          <div className="p-4 bg-red-50 text-red-600 rounded-lg mb-4">
            {error}
          </div>
        )}

        <div className="space-y-4">
          {activeTab === 'completed' ? (
            completedActivities.length > 0 ? (
              completedActivities.map(renderCompletedItem)
            ) : (
              <div className="text-center py-8 text-gray-500">
                <p className="text-lg">No Completed Transactions</p>
                <button
                  onClick={() => navigate('/browse')}
                  className="mt-4 text-[#FF4C03] font-medium"
                >
                  Browse Available Tickets
                </button>
              </div>
            )
          ) : activeTab === 'buying' ? (
            buyingActivities.length > 0 ? (
              buyingActivities.map(renderActivityItem)
            ) : (
              <div className="text-center py-8 text-gray-500">
                <p className="text-lg">No Live Buying Activity</p>
                <button
                  onClick={() => navigate('/browse')}
                  className="mt-4 text-[#FF4C03] font-medium"
                >
                  Browse Available Tickets
                </button>
              </div>
            )
          ) : (
            sellingActivities.length > 0 ? (
              sellingActivities.map(renderActivityItem)
            ) : (
              <div className="text-center py-8 text-gray-500">
                <p className="text-lg">No Live Selling Activity</p>
                <button
                  onClick={() => navigate('/browse')}
                  className="mt-4 text-[#FF4C03] font-medium"
                >
                  List A Ticket
                </button>
              </div>
            )
          )}
        </div>
      </div>

      {/* Price Update Modal */}
      {showPriceModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-sm">
            <h3 className="text-lg font-semibold mb-4">Update Listing Price</h3>
            <p className="text-sm text-gray-600 mb-4">
              Are you sure you want to modify this listing? This action cannot be undone.
            </p>
            <div className="flex space-x-4">
              <button
                onClick={() => {
                  setShowPriceModal(false);
                  setEditingListing(null);
                  setNewPrice('');
                }}
                className="flex-1 py-2 border border-gray-300 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => handleEditPrice(editingListing)}
                className="flex-1 py-2 bg-[#FF4C03] text-white rounded-lg"
              >
                Change to ${newPrice}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityPage;