import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import AnimatedPrice from '../../components/common/AnimatedPrice';
import { useWebSocket } from "../../hooks/useWebSocket";  
import { apiConfig, getFetchConfig } from '../../config/api';

const BrowsePage = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [events, setEvents] = useState([]);
  const [previousPrices, setPreviousPrices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userListings, setUserListings] = useState({});
  const navigate = useNavigate();

  // In BrowsePage.jsx
const handleEventUpdate = (message) => {
  console.log('Received WebSocket message:', message);
  
  // Safely handle the message data
  if (message?.eventId) {
    setEvents(prevEvents => 
      prevEvents.map(event => {
        if (event._id === message.eventId) {
          return {
            ...event,
            lowestPrice: message.data?.lowestPrice ?? event.lowestPrice
          };
        }
        return event;
      })
    );
  }
};

  // Initialize WebSocket
  useWebSocket(handleEventUpdate);

  useEffect(() => {
    const fetchEventsAndListings = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }
    
        // First fetch events only
        const eventsResponse = await fetch(
          `${apiConfig.events.list('Indiana University')}`,
          getFetchConfig(token)
        );
    
        if (!eventsResponse.ok) {
          if (eventsResponse.status === 401) {
            localStorage.removeItem("token");
            navigate("/login");
            return;
          }
          throw new Error("Failed to fetch events");
        }
    
        const eventsData = await eventsResponse.json();
        const events = Array.isArray(eventsData) ? eventsData : [];
        
        // Then fetch listings for each event
        const eventsWithListings = await Promise.all(
          events.map(async (event) => {
            try {
              const listingsResponse = await fetch(
                apiConfig.listings.getForEvent(event._id),
                getFetchConfig(token)
              );
              
              if (listingsResponse.ok) {
                const { listings, lowestPrice, event: updatedEvent } = await listingsResponse.json();
                
                return {
                  ...event,
                  ...updatedEvent,
                  lowestPrice,
                  hasActiveListings: listings?.length > 0,
                  listings
                };
              }
              return event;
            } catch (error) {
              console.error(`Error fetching listings for event ${event._id}:`, error);
              return event;
            }
          })
        );
    
        // Update events state with listing data
        setEvents(eventsWithListings);
    
        // Create map of user's listings
        const userListingsMap = {};
        const userId = localStorage.getItem('userId');
        eventsWithListings.forEach(event => {
          if (event.listings?.length > 0) {
            const userListing = event.listings.find(l => 
              l.sellerId?._id === userId && l.status === 'active'
            );
            if (userListing) {
              userListingsMap[event._id] = userListing;
            }
          }
        });
    
        setUserListings(userListingsMap);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "Failed to load events and listings");
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchEventsAndListings();
  }, [navigate]);


  // In BrowsePage.jsx
  const handleEventClick = (eventId) => {
    // Check if user has a listing for this event
    if (userListings[eventId]) {
      navigate(`/event/${eventId}/listing-success`, {
        state: {
          price: userListings[eventId].price,
          eventId,
          listingId: userListings[eventId]._id
        }
      });
    } else {
      // Check if event has any listings
      const event = events.find(e => e._id === eventId);
      if (!event?.hasActiveListings) {
        // No listings - go directly to listing page
        navigate(`/event/${eventId}/list`);  // Missing the state that the + button preserves
      } else {
        // Has listings - go to event details
        navigate(`/event/${eventId}`);
      }
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe && activeSlide < events.length - 1) {
      setActiveSlide((prev) => prev + 1);
    }
    if (isRightSwipe && activeSlide > 0) {
      setActiveSlide((prev) => prev - 1);
    }

    setTouchStart(0);
    setTouchEnd(0);
  };

  const formatTitle = (title) => {
    const parts = title.split(" at ");
    return (
      <span>
        {parts[0]}
        <span className="text-[#999999] font-normal"> at </span>
        {parts[1]}
      </span>
    );
  };

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return new Date(date).toLocaleDateString("en-US", options);
  };

  const formatTime = (time) => {
    console.log("Time input:", time); // Log to see the exact time value
  
    if (!time || time === "TBD") return "TBD"; // Return "TBD" if time is missing or set to "TBD"
  
    try {
      const [hours, minutes] = time.split(":");
      const timeObj = new Date();
      timeObj.setHours(parseInt(hours), parseInt(minutes));
  
      return timeObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
      });
    } catch (error) {
      console.error("Time formatting error:", error);
      return "Invalid Time"; // Fallback if there's an error in formatting
    }
  };
  if (isLoading) {
    return (
      <div className="flex flex-col h-screen bg-white font-['Poppins']">
        <div className="flex-1 flex items-center justify-center">
          <Loader />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col h-screen bg-white font-['Poppins']">
        <div
          className="w-full"
          style={{ paddingTop: "env(safe-area-inset-top)" }}
        />
        <div className="flex-1 flex items-center justify-center">
          <p className="text-red-600">Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-white font-['Poppins'] overflow-hidden">
      {/* Status Bar Space */}
      <div
        className="w-full"
        style={{ paddingTop: "env(safe-area-inset-top)" }}
      />

      {/* Fixed Header */}
      <div className="px-6 py-4 border-b border-[#CDCDCD]">
        <h1 className="text-[26.26px] font-bold text-black">
          Indiana University
        </h1>
        <p className="text-[19.69px] font-medium text-[#909090]">Featured</p>
      </div>

{/* Main Content Area with Fixed Height Carousel */}
<div className="flex flex-col h-[calc(100%-env(safe-area-inset-top)-env(safe-area-inset-bottom)-60px)]">
  {/* Carousel Section - Fixed Height */}
  <div className="w-full overflow-hidden">
  <div
  className="flex scroll-smooth"
  onTouchStart={handleTouchStart}
  onTouchMove={handleTouchMove}
  onTouchEnd={handleTouchEnd}
  style={{
    transform: `translateX(calc(-${activeSlide * 100}% + ${activeSlide * 40}px))`,
    transition: "transform 0.3s ease-out",
    scrollBehavior: "smooth" // Ensures smooth scrolling
  }}
>
      {events.map((event, index) => (
        <div
          key={event._id}
          onClick={() => handleEventClick(event._id)}
          className="w-full px-6 pt-5 pb-5 flex-shrink-0"
          style={{
            transform: `translateX(-${index * 40}px)`,
            paddingRight: index < events.length - 1 ? "40px" : "24px",
          }}
        >
          
          <div className="relative overflow-hidden">
            <img
              src={event.imageUrl}
              alt={event.title}
              className="w-full h-[200px] object-cover rounded-lg"
            />
            {/* In the carousel section */}
            <div className="mt-4">
              <div className="flex justify-between items-center">
                {/* Event Title and Price */}
                <div>
                  <h3 className="text-[19px] font-bold">
                    {event.title.split(" at ")[0]}
                    <span className="text-[#999999]"> at </span>
                    {event.title.split(" at ")[1]}
                  </h3>
                  <div className="flex items-center">
              {event.lowestPrice ? (
                 <>
                <span className="text-[#909090] font-normal">From </span>
                 <span className="text-[#FF4C03] font-bold text-base ml-1.5">
                  ${event.lowestPrice}
                </span>
              </>
               ) : (
                 <div className="invisible flex items-center">
                <span className="text-[#909090] font-normal">From </span>
                <span className="text-[#FF4C03] font-bold text-base ml-1.5">
                $0
              </span>
             </div>
                  )}
                  </div>
                </div>
                {/* Event Date and Time */}
                <div className="text-right text-[13px] text-[#909090] font-medium">
                  <div>{formatDate(event.date)}</div>
                  <div>{formatTime(event.time)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>

        {/* Scrollable Event List with Sticky Header */}
<div className="flex-1 overflow-hidden flex flex-col">
  <div className="sticky top-0 z-10 bg-white border-b border-[#CDCDCD]">
    <div className="px-6 py-2 flex justify-between items-center">
      <h2 className="text-[26px] font-bold">All Events</h2>
      <span className="text-[15px] font-semibold">Lowest Listing</span>
    </div>
  </div>

  <div className="flex-1 overflow-y-auto pb-25">
    {events.map((event) => (
      <div
        key={event._id}
        onClick={() => handleEventClick(event._id)}
        className="px-6 py-3 flex justify-between items-center border-b border-[#CDCDCD] cursor-pointer"
      >
        <div>
          <h3 className="text-[16px] font-bold">
            {formatTitle(event.title)}
          </h3>
          <p className="text-[13px] text-[#909090] font-medium">
            {formatDate(event.date)}{event.time ? ` | ${formatTime(event.time)}` : ""}
          </p>
        </div>
        <div className="flex flex-col items-end">
          {event.lowestPrice ? (
            <AnimatedPrice 
              value={event.lowestPrice}
              className="text-[#FF4C03] font-bold text-[22px]" // Set pixel size here
            />
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/event/${event._id}/list`);
              }}
              className="w-8 h-8 rounded-full border border-[#FF4C03] text-[#FF4C03] flex items-center justify-center text-xl font-medium"
            >
              +
            </button>
          )}
          <span className="text-[12px] text-[#909090]">
            {event.sport || "Football"}
          </span>
        </div>
      
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default BrowsePage;