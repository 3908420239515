// src/hooks/usePaymentProcessing.js
import { useState } from 'react';
import { apiConfig } from '../config/api';

export const usePaymentProcessing = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const processPayment = async (amount, listingId) => {
    setIsProcessing(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      
      // Create payment intent
      const response = await fetch('/api/payments/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          amount,
          listingId,
          capture_method: 'manual'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to process payment');
      }

      return await response.json();

    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    isProcessing,
    error,
    processPayment
  };
};