// src/pages/events/BuyPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { EventHeader } from '../../components/events/review/EventHeader';
import { PurchaseSummary } from '../../components/payment/PurchaseSummary';
import { PaymentMethodSelector } from '../../components/payment/PaymentMethodSelector';
import { FeesInfo } from '../../components/payment/FeesInfo';
import { ApplePayButton } from '../../components/payment/ApplePayButton';
import StripeWrapper from '../../components/payment/StripeWrapper';
import Loader from '../../components/common/Loader';
import { usePaymentProcessing } from '../../hooks/usePaymentProcessing';
import { apiConfig, getFetchConfig } from '../../config/api';
import { calculateFees } from '../../utils/feeCalculations';

const BuyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useParams();
  const { processPayment, isProcessing, error: paymentError } = usePaymentProcessing();
  
  const [event, setEvent] = useState(null);
  const [listing, setListing] = useState(null);
  const [isFeesExpanded, setIsFeesExpanded] = useState(false);
  const [savedPaymentMethod, setSavedPaymentMethod] = useState(null);
  const [isPaymentFormExpanded, setIsPaymentFormExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Calculate total amount and fees
  const totalAmount = listing ? calculateFees(listing.price) : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        if (!location.state?.listingId) {
          navigate('/browse');
          return;
        }

        const [eventResponse, listingsResponse] = await Promise.all([
          fetch(apiConfig.events.detail(eventId), getFetchConfig(token)),
          fetch(apiConfig.listings.getForEvent(eventId), getFetchConfig(token))
        ]);

        if (!eventResponse.ok || !listingsResponse.ok) {
          throw new Error('Failed to fetch required data');
        }

        const eventData = await eventResponse.json();
        const listingsData = await listingsResponse.json();
        
        setEvent(eventData);
        
        const targetListing = listingsData.listings.find(l => 
          l._id === location.state?.listingId && l.status === 'active'
        );

        if (!targetListing) {
          navigate(`/event/${eventId}`);
          return;
        }

        setListing(targetListing);
      } catch (error) {
        console.error('Error:', error);
        setError('Failed to load ticket details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [eventId, location.state?.listingId, navigate]);

  const handlePaymentSuccess = async (paymentIntent) => {
    try {
      const token = localStorage.getItem('token');
      
      // Update listing status
      const response = await fetch(`${apiConfig.listings.update(listing._id)}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status: 'sale_in_progress',
          paymentIntentId: paymentIntent.id
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update listing');
      }

      // Navigate to confirmation
      navigate(`/event/${eventId}/purchase-confirmation`, {
        state: { 
          listingId: listing._id,
          paymentIntentId: paymentIntent.id 
        }
      });
    } catch (error) {
      console.error('Error updating listing:', error);
      setError('Failed to complete purchase');
    }
  };

  if (isLoading || !totalAmount) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <Loader />
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex flex-col bg-white">
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
      <EventHeader 
        event={event}
        ticketInfo={{
          ticketType: listing?.ticketType,
          section: listing?.section,
          row: listing?.row,
          seat: listing?.seat
        }}
        onBack={() => navigate(`/event/${eventId}`)}
      />

      <div className="flex-1 overflow-y-auto">
        <div className="px-6 py-6">
          {(error || paymentError) && (
            <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-lg text-center">
              {error || paymentError}
            </div>
          )}

          <PurchaseSummary price={listing.price} />

          <p className="text-center text-xs text-gray-600 my-6">
            A hold of ${totalAmount.total.toFixed(2)} will be placed on your payment method. 
            The charge will only be completed after the ticket transfer is verified.
          </p>

          <FeesInfo 
            isExpanded={isFeesExpanded}
            onToggle={() => setIsFeesExpanded(!isFeesExpanded)}
          />


<StripeWrapper amount={totalAmount.total}>
  <PaymentMethodSelector
    savedPaymentMethod={savedPaymentMethod}
    isPaymentFormExpanded={isPaymentFormExpanded}
    onPaymentMethodChange={setSavedPaymentMethod}
    onPaymentFormToggle={setIsPaymentFormExpanded}
    amount={totalAmount.total}
    eventId={event._id}
    listingId={listing._id}
    onSuccess={handlePaymentSuccess}
    onError={(error) => setError(error.message)}
  />
  <ApplePayButton
    amount={totalAmount.total}
    eventId={event._id}
    listingId={listing._id}
    onSuccess={handlePaymentSuccess}
    onError={(error) => setError(error.message)}
  />
</StripeWrapper>
        </div>
      </div>
    </div>
  );
};

export default BuyPage;