import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2, AlertCircle, Upload } from 'lucide-react';
import { TransferProgressBar } from '../../TransferProgressBar';
import { apiConfig } from '../../../../../config/api';

export const TransferInitiated = ({ 
  buyerInfo, 
  event,
  listingId,
  onScreenshotUpload,
  transferError 
}) => {

  useEffect(() => {
    console.log('TransferInitiated props:', {
      hasBuyerInfo: !!buyerInfo,
      buyerDetails: buyerInfo,
      hasEvent: !!event,
      eventDetails: event,
      listingId
    });
  }, [buyerInfo, event, listingId]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [verificationState, setVerificationState] = useState({
    isVerifying: false,
    verified: false,
    error: null
  });

  const verifyScreenshot = async (file) => {
    try {
      setVerificationState(prev => ({ ...prev, isVerifying: true, error: null }));
      
      // Validate required data
      if (!buyerInfo || !buyerInfo.firstName || !buyerInfo.lastName || !buyerInfo.email) {
        throw new Error('Missing buyer information');
      }

      if (!event || !event.date) {
        throw new Error('Missing event information');
      }

      // Format the date
      const formatEventDate = (date) => {
        if (!date) {
          console.error('No date provided to formatEventDate');
          return null;
        }
  
        try {
          const dateObj = new Date(date);
          if (isNaN(dateObj.getTime())) {
            console.error('Invalid date:', date);
            return null;
          }
  
          return dateObj.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          }).toLowerCase();
        } catch (error) {
          console.error('Date formatting error:', error);
          return null;
        }
      };

      const eventDate = formatEventDate(event.date);
      console.log('Date formatting:', {
        rawDate: event.date,
        formattedDate: eventDate,
        buyerName: `${buyerInfo.firstName} ${buyerInfo.lastName}`,
        buyerEmail: buyerInfo.email
      });

      if (!eventDate) {
        throw new Error('Could not format event date properly');
      }

      // Create form data with verification info
      const formData = new FormData();
      formData.append('screenshot', file);
      formData.append('verificationData', JSON.stringify({
        buyerName: `${buyerInfo.firstName} ${buyerInfo.lastName}`,
        buyerEmail: buyerInfo.email,
        eventDate,
        requiredText: 'transfer' // Add this as a fallback text to search for
      }));
  

      const token = localStorage.getItem('token');
      const response = await fetch(apiConfig.ocr.verifyScreenshot, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      const data = await response.json();
      console.log('Verification response:', data);

      if (!data.isValid) {
        let errorMessage = 'Unable to verify the transfer screenshot.';
        
        if (data.errorType === 'wrong_event') {
          errorMessage = 'This screenshot appears to be for a different event.';
        } else if (data.errorType === 'name_mismatch') {
          errorMessage = 'Please ensure the recipient details match exactly.';
        } else if (data.errorType === 'email_mismatch') {
          errorMessage = 'The recipient email appears incorrect.';
        } else if (data.errorType === 'not_transfer') {
          errorMessage = 'This doesn\'t appear to be a transfer confirmation screenshot.';
        }

        setVerificationState({
          isVerifying: false,
          verified: false,
          error: errorMessage
        });
        return false;
      }

      setVerificationState({
        isVerifying: false,
        verified: true,
        error: null
      });
      return true;

    } catch (error) {
      console.error('Verification error:', error);
      setVerificationState({
        isVerifying: false,
        verified: false,
        error: 'Unable to process the screenshot. Please try again.'
      });
      return false;
    }
  };

  const handleFileSelect = useCallback(async (e) => {
    const file = e.target.files[0];
    
    if (!file) return;
  
    if (!file.type.startsWith('image/')) {
      setVerificationState(prev => ({
        ...prev,
        error: 'Please select an image file'
      }));
      return;
    }
    
    if (file.size > 5 * 1024 * 1024) {
      setVerificationState(prev => ({
        ...prev,
        error: 'File size must be less than 5MB'
      }));
      return;
    }
  
    setSelectedFile(file);
    await verifyScreenshot(file);
  }, [buyerInfo, event]);

  const handleUploadAndTransfer = async () => {
    if (!selectedFile || !verificationState.verified) return;
  
    setIsUploading(true);
    
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('screenshot', selectedFile);
  
      const uploadResponse = await fetch(
        apiConfig.listings.uploadScreenshot(listingId),
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        }
      );
  
      if (!uploadResponse.ok) {
        throw new Error('Failed to upload transfer confirmation');
      }
  
      const responseData = await uploadResponse.json();
      console.log('Upload successful:', responseData);
      
    } catch (error) {
      console.error('Upload error:', error);
      setVerificationState(prev => ({
        ...prev,
        error: 'Failed to upload transfer confirmation'
      }));
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="flex flex-col items-center pt-6">
      <h2 className="text-2xl font-semibold text-center">
        Transfer Initiated
      </h2>
      
      <p className="text-center text-sm mb-8">
        Please go to{' '}
        <a 
          href="https://iuhoosiers.evenue.net/signin" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-black underline"
        >
          https://iuhoosiers.evenue.net/signin
        </a>
        {' '}to complete the transfer process.
      </p>

      {buyerInfo && (
        <div className="w-full mb-4 p-4 border border-gray-300 rounded-lg">
          <h3 className="font-semibold mb-2 text-center">Transfer To</h3>
          <p className="text-center">
            {buyerInfo.firstName} {buyerInfo.lastName}
            <br />
            {buyerInfo.email}
          </p>
        </div>
      )}

      <TransferProgressBar status="transfer_initiated" />
      
      <div className="w-full mt-8 mb-4">
        <div 
          className={`border-2 border-dashed rounded-lg p-6 text-center transition-colors duration-200
            ${verificationState.verified ? 'border-green-500 bg-green-50' : 
              selectedFile ? 'border-gray-300' : 
              'border-gray-300 hover:border-gray-400'}`}
        >
          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="transferScreenshot"
            onChange={handleFileSelect}
            disabled={isUploading}
          />
          <label 
            htmlFor="transferScreenshot"
            className="cursor-pointer flex flex-col items-center justify-center"
          >
            {verificationState.verified ? (
              <>
                <CheckCircle2 className="w-8 h-8 text-green-500 mb-2" />
                <p className="text-sm font-medium text-gray-900">Transfer Confirmed</p>
                <p className="text-xs text-gray-500 mt-1">Click to upload a different confirmation</p>
              </>
            ) : selectedFile ? (
              <>
                {verificationState.isVerifying ? (
                  <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-2" />
                    <p className="text-sm font-medium text-gray-900">Verifying transfer...</p>
                  </div>
                ) : (
                  <>
                    <AlertCircle className="w-8 h-8 text-red-500 mb-2" />
                    <p className="text-sm font-medium text-red-600">Unable to verify transfer</p>
                    <p className="text-xs text-gray-500 mt-1">Click to try again</p>
                  </>
                )}
              </>
            ) : (
              <>
                <Upload className="w-8 h-8 text-gray-400 mb-2" />
                <p className="text-sm font-medium text-gray-900">Upload Transfer Confirmation</p>
                <p className="text-xs text-gray-500 mt-1">PNG, JPG up to 5MB</p>
              </>
            )}
          </label>
        </div>
      </div>

      {verificationState.error && (
        <div className="w-full p-4 bg-red-50 text-red-600 rounded-lg mb-4">
          {verificationState.error}
        </div>
      )}

      <button
        onClick={handleUploadAndTransfer}
        disabled={!verificationState.verified || isUploading}
        className={`w-full py-4 text-white font-bold text-base rounded-full
          transition-colors duration-200 ${
            verificationState.verified 
              ? 'bg-[#FF4C03] hover:bg-[#e55a1b]' 
              : 'bg-gray-300 cursor-not-allowed'
          }`}
      >
        {isUploading ? 'Processing...' : 'Confirm Transfer Complete'}
      </button>
    </div>
  );
};

export default TransferInitiated;