// src/config/domains.js
export const SUPPORTED_DOMAINS = [
    {
      domain: 'iu.edu',
      schoolName: 'Indiana University'
    }
    // Add more schools as we expand:
    // { domain: 'purdue.edu', schoolName: 'Purdue University' },
    // { domain: 'umich.edu', schoolName: 'University of Michigan' }
  ];
  
  export const isValidSchoolEmail = (email) => {
    if (!email || !email.includes('@')) return false;
    const domain = email.split('@')[1];
    return SUPPORTED_DOMAINS.some(school => school.domain === domain);
  };