import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TransferProgressBar } from '../../TransferProgressBar';

const ConfirmationFlow = ({ listingId, eventId, onConfirm }) => {
  const [currentStep, setCurrentStep] = useState('confirm');
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  const handleConfirmReceipt = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/listings/${listingId}/status`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status: 'confirmed'
        })
      });

      if (!response.ok) {
        throw new Error('Failed to confirm receipt');
      }

      // Stay on the same page but move to rating step
      setCurrentStep('rate');
    } catch (error) {
      console.error('Error confirming receipt:', error);
    }
  };

  const handleSubmitRating = async () => {
    try {
      const token = localStorage.getItem('token');
      await fetch(`/api/listings/${listingId}/rate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rating,
          type: 'seller'
        })
      });

      // Navigate to final completion state
      navigate(`/event/${eventId}/listing-success`, {
        state: { 
          listingId,
          eventId,
          status: 'completed',
          showRatingThanks: true
        },
        replace: true // Use replace to prevent back navigation
      });
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  if (currentStep === 'confirm') {
    return (
      <div className="flex flex-col items-center pt-6">
        <h2 className="text-2xl font-semibold text-center mb-2">
          Confirmation Required
        </h2>
        
        <p className="text-center text-sm mb-8">
          The seller has completed the transfer, which has been verified by SectionU.
        </p>

        <TransferProgressBar status="ticket_sent" />

        <p className="text-center text-sm mt-4 mb-2">
          Failure to confirm receipt of transfer below may result in account review and/or suspension.
          If you have not received the ticket please message the seller below.
        </p>

        <div className="w-full mt-8 space-y-4">
          <button
            onClick={handleConfirmReceipt}
            className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full"
          >
            Confirm Receipt
          </button>

          <button
            className="w-full py-4 border-2 border-[#FF4C03] text-[#FF4C03] rounded-full"
          >
            Message Seller
          </button>
        </div>
      </div>
    );
  }

  if (currentStep === 'rate') {
    return (
      <div className="flex flex-col items-center pt-6">
        <h2 className="text-2xl font-semibold text-center mb-4">
          Rate the seller
        </h2>

        <div className="flex space-x-2 my-6">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => setRating(star)}
              className={`text-3xl ${
                star <= rating ? 'text-[#FF4C03]' : 'text-gray-300'
              }`}
            >
              ★
            </button>
          ))}
        </div>

        <button
          onClick={handleSubmitRating}
          disabled={!rating}
          className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full disabled:opacity-50 mt-4"
        >
          Submit Rating
        </button>
      </div>
    );
  }

  return null;
};

export default ConfirmationFlow;