import React from 'react';

const Step = ({ isCompleted, isActive, isAnimating, isLast }) => (
  <div className="flex items-center w-full">
    {/* Dot */}
    <div
      className={`w-4 h-4 rounded-full ${
        isCompleted || isActive ? 'bg-[#FF4C03]' : 'bg-gray-200'
      }`}
    />
    {/* Connecting Line */}
    {!isLast && (
      <div className="flex-grow h-[5px] relative">
        <div
          className={`absolute inset-0 ${
            isCompleted ? 'bg-[#FF4C03]' : 'bg-gray-200'
          }`}
        />
        {isAnimating && (
          <div
            className="absolute top-0 left-0 h-full bg-[#FF4C03] animate-pulsate"
            style={{ animation: 'pulsate 2s infinite ease-in-out' }}
          />
        )}
      </div>
    )}
  </div>
);

const StatusLabel = ({ text, isActive }) => {
  // Special handling for "Ticket marked as sent"
  if (text === 'Ticket marked as sent') {
    return (
      <div
        className={`text-[11px] leading-tight text-center ${
          isActive ? 'text-[#FF4C03]' : 'text-[#6B7280]'
        }`}
      >
        Ticket marked
        <br />
        as sent
      </div>
    );
  }

  // Normal two-line label handling
  return (
    <div
      className={`text-[11px] leading-tight text-center ${
        isActive ? 'text-[#FF4C03]' : 'text-[#6B7280]'
      }`}
    >
      {text.split(' ').map((part, i, arr) => (
        <React.Fragment key={i}>
          {part}
          {i < arr.length - 1 && ' '}
          {i === 0 && <br />}
        </React.Fragment>
      ))}
    </div>
  );
};

export const TransferProgressBar = ({ status }) => {
  const steps = [
    { id: 'initiated', label: 'Transfer initiated' },
    { id: 'marked', label: 'Ticket marked as sent' },
    { id: 'complete', label: 'Transaction complete' },
  ];

  const getStepState = (stepId) => {
    switch (status) {
      case 'sale_in_progress':
        return {
          completed: false,
          active: false,
        };
      case 'transfer_initiated':
        return {
          completed: false,
          active: stepId === 'initiated',
        };
      case 'ticket_sent':
        return {
          completed: stepId === 'initiated',
          active: stepId === 'marked',
        };
      case 'complete':
        return {
          completed: stepId === 'initiated' || stepId === 'marked',
          active: stepId === 'complete',
        };
      default:
        return {
          completed: false,
          active: false,
        };
    }
  };

  return (
    <div className="w-full my-4">
      {/* Progress Bar */}
      <div className="flex justify-between w-full mb-3">
        {/* Separate containers for each step */}
        <div className="flex items-center w-full justify-start">
          <Step
            isCompleted={getStepState('initiated').completed}
            isActive={getStepState('initiated').active}
            isAnimating={getStepState('initiated').active}
            isLast={false}
          />
        </div>
        <div className="flex items-center w-full justify-center">
          <Step
            isCompleted={getStepState('marked').completed}
            isActive={getStepState('marked').active}
            isAnimating={getStepState('marked').active}
            isLast={false}
          />
        </div>
        <div className="flex items-center  justify-end">
          <Step
            isCompleted={getStepState('complete').completed}
            isActive={getStepState('complete').active}
            isAnimating={getStepState('complete').active}
            isLast={true}
          />
        </div>
      </div>
      {/* Status Labels */}
      <div className="flex justify-between w-full">
        {steps.map((step, index) => (
          <div
            key={step.id}
            className={`w-1/3 flex ${
              index === 0
                ? 'justify-start'
                : index === 1
                ? 'justify-center'
                : 'justify-end'
            }`}
          >
            <StatusLabel
              text={step.label}
              isActive={getStepState(step.id).active}
            />
          </div>
        ))}
      </div>
    </div>
  );
};