// src/context/TicketPurchaseContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useWebSocket } from '../hooks/useWebSocket';
import { apiConfig, getFetchConfig } from '../config/api';

const TicketPurchaseContext = createContext(null);

export const TicketPurchaseProvider = ({ children }) => {
  const [purchaseState, setPurchaseState] = useState({
    status: null,
    listing: null,
    event: null,
    buyerInfo: null,
    error: null,
    isLoading: true,
  });
  
  const updatePurchaseState = useCallback((updates) => {
    setPurchaseState(prev => ({
      ...prev,
      ...updates
    }));
    console.log('Purchase state updated:', updates);
  }, []);

  const handleWebSocketMessage = useCallback((message) => {
    console.log('WebSocket message received in TicketPurchaseContext:', message);
    
    if (message.type === 'LISTING_UPDATE' && purchaseState.listing?._id === message.listingId) {
      updatePurchaseState({
        status: message.status,
        buyerInfo: message.data?.buyerInfo
      });
    }
  }, [purchaseState.listing, updatePurchaseState]);

  useWebSocket(handleWebSocketMessage);

  const fetchPurchaseDetails = useCallback(async (listingId, eventId) => {
    if (!listingId || !eventId) return;

    try {
      updatePurchaseState({ isLoading: true, error: null });

      const token = localStorage.getItem('token');
      if (!token) throw new Error('No auth token');

      const [userResponse, listingsResponse] = await Promise.all([
        fetch(apiConfig.user.profile, getFetchConfig(token)),
        fetch(apiConfig.listings.getForEvent(eventId), getFetchConfig(token))
      ]);

      if (!userResponse.ok || !listingsResponse.ok) {
        throw new Error('Failed to fetch purchase details');
      }

      const userData = await userResponse.json();
      const listingsData = await listingsResponse.json();

      const foundListing = listingsData.listings?.find(l => l._id === listingId);
      if (!foundListing) throw new Error('Listing not found');

      const userIsBuyer = foundListing.buyerId?._id === userData._id;

      updatePurchaseState({
        status: foundListing.status,
        listing: foundListing,
        event: listingsData.event,
        buyerInfo: foundListing.buyerId,
        isCurrentUserBuyer: userIsBuyer,
        isLoading: false
      });

    } catch (error) {
      console.error('Error fetching purchase details:', error);
      updatePurchaseState({
        error: error.message,
        isLoading: false
      });
    }
  }, [updatePurchaseState]);

  const value = {
    ...purchaseState,
    fetchPurchaseDetails,
    updatePurchaseState
  };

  return (
    <TicketPurchaseContext.Provider value={value}>
      {children}
    </TicketPurchaseContext.Provider>
  );
};

export const useTicketPurchase = () => {
  const context = useContext(TicketPurchaseContext);
  if (!context) {
    throw new Error('useTicketPurchase must be used within a TicketPurchaseProvider');
  }
  return context;
};