// src/pages/events/EventDetailPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from "../../components/common/Loader";
import { EventHeader } from '../../components/events/detail/EventHeader';
import { EventImage } from '../../components/events/detail/EventImage';
import { VerificationBadge } from '../../components/events/detail/VerificationBadge';
import { GeneralAdmissionView } from '../../components/events/detail/GeneralAdmissionView';
import { AssignedSeatingView } from '../../components/events/detail/AssignedSeatingView';
import { apiConfig, getFetchConfig } from '../../config/api';
import { useWebSocket } from "../../hooks/useWebSocket";

const EventDetailPage = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('general');

  // src/pages/events/EventDetailPage.jsx

useEffect(() => {
  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);
  
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
  
      // First get the event details
      const eventResponse = await fetch(
        `${apiConfig.events.detail(eventId)}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (!eventResponse.ok) {
        throw new Error('Failed to fetch event details');
      }
  
      const eventData = await eventResponse.json();
  
      // Then get the listings
      const listingsResponse = await fetch(
        `${apiConfig.listings.getForEvent(eventId)}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (!listingsResponse.ok) {
        throw new Error('Failed to fetch listings');
      }
  
      const listingsData = await listingsResponse.json();
      console.log('Listings response:', listingsData);
  
      const activeListings = listingsData.listings?.filter(listing => 
        listing.status === 'active'
      ) || [];
  
      // Check if there are any active listings - if not, go to listing page
      if (activeListings.length === 0) {
        navigate(`/event/${eventId}/list`);
        return;
      }
  
      // Get user profile to determine if they're a buyer
      const userResponse = await fetch(
        apiConfig.user.profile,
        getFetchConfig(token)
      );
  
      if (!userResponse.ok) {
        throw new Error('Failed to get user profile');
      }
  
      const userData = await userResponse.json();
  
      // Check if user is a buyer of any listing
      const userListing = listingsData.listings.find(l => 
        l.buyerId?._id === userData._id && 
        ['sale_in_progress', 'transfer_initiated'].includes(l.status)
      );
  
      if (userListing) {
        // User is a buyer - redirect to purchase confirmation
        navigate(`/event/${eventId}/purchase-confirmation`, {
          state: {
            listingId: userListing._id,
            eventId,
            isBuyer: true
          },
          replace: true
        });
        return;
      }
  
      // Continue with setting event and listings data
      if (eventData) {
        const lowestActivePrice = activeListings.length > 0
          ? Math.min(...activeListings.map(l => l.price))
          : null;
  
        setEvent(prevEvent => ({
          ...prevEvent,
          ...eventData,
          lowestPrice: lowestActivePrice
        }));
      }
  
      setListings(activeListings);
  
      // Let's fix the role check:
      const userRole = activeListings.find(l => {
        // First ensure we have valid seller and buyer data
        const isSeller = l.sellerId && 
          typeof l.sellerId === 'object' && 
          l.sellerId._id === userData._id;
        
        const isBuyer = l.buyerId && 
          typeof l.buyerId === 'object' && 
          l.buyerId._id === userData._id;
        
        // Enhanced logging with safety checks
        console.log('Checking listing role:', {
          listingId: l._id,
          isSeller,
          isBuyer,
          status: l.status,
          sellerIdType: l.sellerId ? typeof l.sellerId : 'null/undefined',
          buyerIdType: l.buyerId ? typeof l.buyerId : 'null/undefined',
          currentUserId: userData._id
        });
      
        // Return appropriate role based on status and ownership
        if (isBuyer && ['sale_in_progress', 'transfer_initiated'].includes(l.status)) {
          return { type: 'buyer', listing: l };
        }
        
        if (isSeller && l.status !== 'sold') {
          return { type: 'seller', listing: l };
        }
      
        return false;
      });
      
      // Enhanced logging for debugging
      console.log('User role determination:', {
        hasRole: !!userRole,
        roleType: userRole?.type,
        listingId: userRole?.listing?._id,
        listingsCount: activeListings.length
      });
  
      if (userRole && userRole.type === 'buyer') {
        console.log('Navigating to purchase confirmation');
        navigate(`/event/${eventId}/purchase-confirmation`, {
          state: { 
            listingId: userRole.listing._id,
            paymentIntentId: userRole.listing.paymentIntentId
          },
          replace: true
        });
        return;
      }
  
      // Then handle seller case
      if (listingsData.hasUserListing && listingsData.userListing) {
        console.log('Navigating to listing success');
        navigate(`/event/${eventId}/listing-success`, {
          state: {
            price: listingsData.userListing.price,
            eventId,
            listingId: listingsData.userListing._id
          },
          replace: true
        });
        return;
      }
  
      // Set initial tab based on available listings
      const hasGeneralListings = activeListings.some(l => l.ticketType === 'general');
      const hasAssignedListings = activeListings.some(l => l.ticketType === 'assigned');
      
      if (!hasGeneralListings && hasAssignedListings) {
        setSelectedTab('assigned');
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message || 'Failed to load event details');
    } finally {
      setIsLoading(false);
    }
  };

    if (eventId) {
      fetchData();
    }
  }, [eventId, navigate]);

  const handleEventUpdate = () => {
    window.location.reload();
  };

  useWebSocket(handleEventUpdate);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white">
        <Loader />
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="min-h-screen bg-white p-6">
        <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
        <div className="flex flex-col items-center justify-center gap-4">
          <p className="text-red-600 text-center">{error || 'Event not found'}</p>
          <button
            onClick={() => navigate('/browse')}
            className="px-6 py-2 bg-primary text-white rounded-full"
          >
            Back to Browse
          </button>
        </div>
      </div>
    );
  }

  const filteredListings = listings.filter(listing => listing.ticketType === selectedTab);
  const hasGeneralListings = listings.some(l => l.ticketType === 'general');
  const hasAssignedListings = listings.some(l => l.ticketType === 'assigned');
  const hasMultipleTypes = hasGeneralListings && hasAssignedListings;

  return (
    <div className="min-h-screen bg-white font-['Poppins']">
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
      <EventHeader 
        event={event}
        selectedTab={selectedTab}
        hasMultipleTypes={hasMultipleTypes}
        onTabChange={setSelectedTab}
        onBack={() => navigate('/browse')}
      />

      <EventImage event={event} />

      <VerificationBadge />

      {selectedTab === 'general' ? (
        <GeneralAdmissionView 
          event={event}
          listings={filteredListings}
        />
      ) : (
        <AssignedSeatingView 
          event={event}
          listings={filteredListings}
        />
      )}
    </div>
  );
};

export default EventDetailPage;