import React, { useState, useEffect, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

const BACKEND_URL = 'https://sectionu-backend.onrender.com';

export const ApplePayButton = ({ amount, onSuccess, onError }) => {
  const stripe = useStripe();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const paymentRequest = useRef(null);

  const isIosSafari = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase()) && 
                     'ApplePaySession' in window;

  useEffect(() => {
    const initializeApplePay = async () => {
      if (!stripe) return;

      try {
        const pr = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: 'SectionU Ticket Purchase',
            amount: Math.round(amount * 100),
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        const canMakePayment = await pr.canMakePayment();
        const isDev = window.location.hostname === 'localhost' || 
                     window.location.hostname.includes('10.0.0');
        const isAvailable = isDev ? isIosSafari : (canMakePayment?.applePay === true);
        
        setIsApplePayAvailable(isAvailable);

        if (isAvailable) {
          paymentRequest.current = pr;

          // Inside the paymentmethod event handler, replace the confirmation code with:

          pr.on('paymentmethod', async (ev) => {
            try {
              const response = await fetch(`${BACKEND_URL}/api/payments/create-payment-intent`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ 
                  amount,
                  eventId,
                  listingId
                })
              });
          
              if (!response.ok) {
                const errorData = await response.json();
                console.error('Payment intent creation failed:', errorData);
                ev.complete('fail');
                throw new Error(errorData.error || 'Failed to create payment intent');
              }
          
              const { clientSecret } = await response.json();
          
              const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: ev.paymentMethod.id,
                },
                {
                  handleActions: false
                }
              );
          
              if (confirmError) {
                console.error('Payment confirmation error:', confirmError);
                ev.complete('fail');
                onError(confirmError.message);
                return;
              }
          
              // Complete the Apple Pay sheet
              ev.complete('success');
          
              // Let the confirmation handler take care of the rest
              window.location.href = `${window.location.origin}/#/payment-confirmation?payment_intent=${paymentIntent.id}&payment_intent_client_secret=${clientSecret}`;
              
            } catch (error) {
              console.error('Payment failed:', error);
              ev.complete('fail');
              onError(error.message || 'Payment failed');
            }
          });
        }
      } catch (error) {
        console.error('Apple Pay initialization error:', error);
        setIsApplePayAvailable(false);
        onError(error.message || 'Failed to initialize Apple Pay');
      }
    };

    initializeApplePay();

    return () => {
      if (paymentRequest.current) {
        paymentRequest.current.removeAllListeners();
        paymentRequest.current = null;
      }
    };
  }, [stripe, amount, onSuccess, onError, isIosSafari]);

  const handleApplePayClick = async () => {
    if (!paymentRequest.current) return;
    
    setIsProcessing(true);
    try {
      await paymentRequest.current.show();
    } catch (error) {
      console.error('Show Apple Pay sheet error:', error);
      onError(error.message || 'Failed to show Apple Pay');
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isApplePayAvailable) return null;

  return (
    <>
      <div className="flex items-center gap-4 my-5">
        <div className="h-px flex-1 bg-gray-300" />
        <span className="text-gray-500">or</span>
        <div className="h-px flex-1 bg-gray-300" />
      </div>

      <button 
        onClick={handleApplePayClick}
        disabled={isProcessing || !stripe || !paymentRequest.current}
        style={{ borderRadius: '50px' }}  // You can set any pixel value you want
  className="w-full py-4 bg-black text-white flex items-center justify-center gap-3 disabled:opacity-50"
        
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="h-6" viewBox="0 0 16 16">
          <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
        </svg>
        {isProcessing ? 'Processing...' : `Pay with Apple Pay`}
      </button>
    </>
  );
};

export default ApplePayButton;