import React from 'react';
import { TransferProgressBar } from '../../TransferProgressBar';

const WaitingForSeller = ({ onContinueBrowsing, onViewAlerts }) => {
  return (
    <>
      <h2 className="text-2xl font-semibold text-center mb-4">
        Waiting for seller...
      </h2>
      <p className="text-center text-sm mb-8">
        Thank you for your order. The seller has been notified and will now initiate the transfer process.
      </p>
      <TransferProgressBar status="waiting_for_seller" />
      <div className="w-full space-y-4 mt-8">
        <button
          onClick={onContinueBrowsing}
          className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full"
        >
          Continue Browsing
        </button>
        <button
          onClick={onViewAlerts}
          className="w-full py-4 bg-[#FFE9DF] text-[#FF4C03] font-bold text-base rounded-full border-2 border-[#FF4C03]"
        >
          View My Alerts
        </button>
      </div>
    </>
  );
};

export default WaitingForSeller;