export const TicketTypeSelector = ({ selected, onSelect }) => (
  <div className="px-6 pt-5 pb-2 bg-white">
    
    <div className="flex gap-4">
      {['general', 'assigned'].map((type) => (
        <button
          key={type}
          onClick={() => onSelect(type)}
          className={`flex-1 h-[75px] rounded-lg border-2 transition-colors ${
            selected === type
              ? 'border-primary bg-[#FFE9DF]'
              : 'border-[#969696]'
          }`}
        >
          <div className="text-[13.13px] font-medium text-center">
            <span className={selected === type ? 'text-primary' : 'text-[#909090]'}>
              {type === 'general' ? 'General\nAdmission' : 'Assigned\nSeat'}
            </span>
          </div>
        </button>
      ))}
    </div>
  </div>
);