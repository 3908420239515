// src/pages/events/ListingSuccessPage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EventHeader } from '../../components/events/review/EventHeader';
import BuyerView from '../../components/events/success/views/BuyerView';
import SellerView from '../../components/events/success/views/SellerView';
import Loader from '../../components/common/Loader';
import { useWebSocket } from '../../hooks/useWebSocket';
import { apiConfig, getFetchConfig } from '../../config/api';

const ListingSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { price, eventId, listingId } = location.state || {};

  const [status, setStatus] = useState('purchase_complete');
  const [listing, setListing] = useState(null);
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCurrentUserBuyer, setIsCurrentUserBuyer] = useState(false);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const [userResponse, listingsResponse] = await Promise.all([
        fetch(apiConfig.user.profile, getFetchConfig(token)),
        fetch(apiConfig.listings.getForEvent(eventId), getFetchConfig(token))
      ]);

      if (!userResponse.ok || !listingsResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const userData = await userResponse.json();
      const listingsData = await listingsResponse.json();
      
      // Find listing including completed ones
      const foundListing = listingsData.listings?.find(l => l._id === listingId);

      // If no listing found at all, then show error
      if (!foundListing) {
        setError('Listing not found');
        return;
      }

      const userIsBuyer = foundListing.buyerId?._id === userData._id;
      const userIsSeller = foundListing.sellerId?._id === userData._id;

      // Allow access if user is buyer or seller, regardless of listing status
      if (!userIsBuyer && !userIsSeller) {
        navigate(`/event/${eventId}`);
        return;
      }

      console.log('Setting listing status:', {
        status: foundListing.status,
        userIsBuyer,
        userIsSeller,
        listingId
      });

      setIsCurrentUserBuyer(userIsBuyer);
      setStatus(foundListing.status);
      setListing(foundListing);
      setEvent(listingsData.event);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInitiateTransfer = async () => {
    try {
      console.log('Starting transfer initiation for listing:', listingId);
      const token = localStorage.getItem('token');

      // Log the full URL being used
      const url = `${apiConfig.listings.updateStatus(listingId)}`;
      console.log('Making request to:', url);

      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status: 'transfer_initiated'
        })
      });

      console.log('Response status:', response.status);
      
      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to initiate transfer');
      }

      // Update local state immediately
      setStatus('transfer_initiated');
      
      // Refresh data to ensure everything is in sync
      await fetchData();

    } catch (error) {
      console.error('Transfer initiation error:', error);
      setError(error.message);
    }
  };

  const handleWebSocketMessage = async (message) => {
    console.log('WebSocket message received:', message);
  
    if (message.type === 'LISTING_UPDATE' && message.listingId === listingId) {
      console.log('Processing listing update:', message);
      
      // Update local state
      setStatus(message.status);
  
      // Refresh data regardless of status change
      await fetchData();
  
      // No automatic navigation for intermediate states
      if (message.status === 'confirmed' || message.status === 'completed') {
        // Only navigate on final states
        if (isCurrentUserBuyer) {
          navigate(`/event/${eventId}/purchase-confirmation`, {
            state: { listingId, eventId, status: message.status },
            replace: true
          });
        }
      }
    }
  };

  // Initialize WebSocket
  useWebSocket(handleWebSocketMessage);

  useEffect(() => {
    if (!eventId || !listingId) {
      navigate('/browse');
      return;
    }
    fetchData();
  }, [eventId, listingId]);

  if (isLoading) return <Loader />;

  if (error) {
    return (
      <div className="p-6 text-center">
        <p className="text-red-600">{error}</p>
        <button
          onClick={() => navigate('/browse')}
          className="mt-4 px-6 py-2 bg-primary text-white rounded-full"
        >
          Return to Browse
        </button>
      </div>
    );
  }

  return (
    <div className="h-screen bg-white">
      <div style={{ paddingTop: 'env(safe-area-inset-top)' }}>
        <EventHeader 
          event={event}
          ticketInfo={listing ? {
            ticketType: listing.ticketType,
            section: listing.section,
            row: listing.row,
            seat: listing.seat
          } : undefined}
          onBack={() => navigate('/browse')}
        />
      </div>

      <div className="px-6 py-6">
        {isCurrentUserBuyer ? (
          <BuyerView
            listing={listing}
            event={event}
            status={status}
            onContinueBrowsing={() => navigate('/browse')}
            onViewAlerts={() => navigate('/alerts')}
          />
        ) : (
          <SellerView
            listing={listing}
            status={status}
            price={price || listing?.price}
            onNavigate={navigate}
            onInitiateTransfer={handleInitiateTransfer}
            event={event}  // Add this
          />
        )}
      </div>
    </div>
  );
};

export default ListingSuccessPage;