// src/components/payment/PurchaseSummary.jsx
import { calculateFees } from '../../utils/feeCalculations';

export const PurchaseSummary = ({ price }) => {
  if (!price) return null; // Add guard clause

  const fees = calculateFees(price);

  return (
    <div className="mb-6 space-y-3">
      <div className="flex justify-between items-center">
        <span className="text-base">Purchase Price</span>
        <span className="text-base font-semibold">${fees.purchasePrice.toFixed(2)}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-base">Transaction Fee (Fixed)</span>
        <span className="text-base">+${fees.transactionFee.toFixed(2)}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-base">Processing Fee (2.9% + $0.30)</span>
        <span className="text-base">+${fees.processingFee.toFixed(2)}</span>
      </div>
      <div className="flex justify-between items-center pt-2 border-t border-gray-200">
        <span className="text-lg font-semibold">Total</span>
        <span className="text-lg font-semibold">${fees.total.toFixed(2)}</span>
      </div>
    </div>
  );
};