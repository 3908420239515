// src/hooks/useWebSocket.js
import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isDevelopment, WS_BASE_URL } from '../config/api';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;
const PING_INTERVAL = 30000; // 30 seconds

export const useWebSocket = (onMessage) => {
  const ws = useRef(null);
  const reconnectTimeout = useRef(null);
  const pingInterval = useRef(null);
  const retryCount = useRef(0);
  const messageHandlerRef = useRef(onMessage);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Update message handler ref when callback changes
  useEffect(() => {
    messageHandlerRef.current = onMessage;
  }, [onMessage]);

  const handleClose = useCallback(() => {
    setIsConnected(false);
    clearInterval(pingInterval.current);

    if (retryCount.current < MAX_RETRIES) {
      console.log(`WebSocket reconnecting... Attempt ${retryCount.current + 1} of ${MAX_RETRIES}`);
      retryCount.current += 1;
      reconnectTimeout.current = setTimeout(connect, RETRY_DELAY);
    } else {
      setError('Maximum reconnection attempts reached');
      console.error('WebSocket: Max reconnection attempts reached');
    }
  }, []);

  const setupPing = useCallback(() => {
    if (pingInterval.current) {
      clearInterval(pingInterval.current);
    }

    pingInterval.current = setInterval(() => {
      if (ws.current?.readyState === WebSocket.OPEN) {
        try {
          ws.current.send(JSON.stringify({ type: 'PING' }));
        } catch (error) {
          console.error('WebSocket ping error:', error);
          handleClose();
        }
      }
    }, PING_INTERVAL);
  }, [handleClose]);

  const connect = useCallback(() => {
    try {
      if (ws.current?.readyState === WebSocket.OPEN) {
        return;
      }
  
      const token = localStorage.getItem('token');
      if (!token) {
        // Don't navigate, just don't connect
        console.log('No token found, skipping WebSocket connection');
        return;
      }
  

      if (ws.current) {
        ws.current.close();
      }

      // Construct WebSocket URL
      const wsUrl = `${WS_BASE_URL}?token=${token}`;
      console.log(`Attempting WebSocket connection to ${isDevelopment ? 'development' : 'production'} server...`);
      
      ws.current = new WebSocket(wsUrl);

      ws.current.onopen = () => {
        console.log('WebSocket connected successfully');
        setIsConnected(true);
        setError(null);
        retryCount.current = 0;
        setupPing();
        
        if (reconnectTimeout.current) {
          clearTimeout(reconnectTimeout.current);
          reconnectTimeout.current = null;
        }
      };

      ws.current.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          
          if (isDevelopment) {
            console.log('WebSocket message received:', message);
          }
          
          // Handle different message types
          switch (message.type) {
            case 'CONNECTION_ESTABLISHED':
              console.log('Connection established, userId:', message.userId);
              return;
            
            case 'PONG':
              // Handle server pong response
              return;

            case 'LISTING_UPDATE':
              // Transform message for handler
              const transformedMessage = {
                ...message,
                data: {
                  ...message.data,
                  lowestPrice: message.lowestPrice || message.data?.lowestPrice,
                  previousPrice: message.previousPrice || message.data?.previousPrice
                }
              };
              
              if (messageHandlerRef.current) {
                messageHandlerRef.current(transformedMessage);
              }
              break;

            default:
              if (messageHandlerRef.current) {
                messageHandlerRef.current(message);
              }
          }
        } catch (error) {
          console.error('WebSocket message parsing error:', error);
          setError('Failed to parse WebSocket message');
        }
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setError('WebSocket connection error');
        setIsConnected(false);
      };

      ws.current.onclose = (event) => {
        console.log('WebSocket closed:', {
          code: event.code,
          reason: event.reason,
          wasClean: event.wasClean
        });
        handleClose();
      };

    } catch (error) {
      console.error('WebSocket connection error:', error);
      setError('Failed to establish WebSocket connection');
      setIsConnected(false);
    }
  }, [navigate, setupPing, handleClose]);

  // Initial connection setup
  useEffect(() => {
    const initialConnectTimeout = setTimeout(connect, 1000);

    return () => {
      // Cleanup function
      clearTimeout(initialConnectTimeout);
      clearTimeout(reconnectTimeout.current);
      clearInterval(pingInterval.current);
      
      if (ws.current) {
        // Send a close message if possible
        if (ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ type: 'CLIENT_DISCONNECT' }));
        }
        ws.current.close(1000, 'Component unmounted');
      }
    };
  }, [connect]);

  // Reconnect if token changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token') {
        if (!e.newValue) {
          // Token removed, close connection
          if (ws.current) {
            ws.current.close();
          }
        } else {
          // Token changed, reconnect
          connect();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [connect]);

  return { 
    isConnected, 
    error,
    reconnect: connect // Expose reconnect function
  };
};

export default useWebSocket;