// src/components/common/AnimatedPrice.jsx
import React, { useEffect, useRef, useState } from 'react';

const AnimatedDigit = ({ digit, previousDigit, animate }) => {
  const digitRef = useRef(null);

  useEffect(() => {
    if (animate && digitRef.current && digit !== previousDigit) {
      const element = digitRef.current;
      const isIncreasing = digit > previousDigit;
      
      element.classList.remove('digit-increase', 'digit-decrease');
      void element.offsetWidth;
      element.classList.add(isIncreasing ? 'digit-increase' : 'digit-decrease');
    }
  }, [digit, previousDigit, animate]);

  return (
    <span ref={digitRef} className="inline-block">
      {digit}
    </span>
  );
};

const AnimatedPrice = ({ value, className = "" }) => {
  const [previousValue, setPreviousValue] = useState(null);
  const [isFirstAnimation, setIsFirstAnimation] = useState(true);

  useEffect(() => {
    if (previousValue === null && typeof value === 'number') {
      // First value received - trigger increase animation
      setPreviousValue(0); // Start from 0
      setIsFirstAnimation(true);
    } else if (typeof value === 'number') {
      setPreviousValue(prev => {
        setIsFirstAnimation(false);
        return prev === null ? value : prev;
      });
    }
  }, [value]);

  useEffect(() => {
    if (typeof value === 'number' && previousValue !== value) {
      const timer = setTimeout(() => {
        setPreviousValue(value);
      }, 600); // Match animation duration
      return () => clearTimeout(timer);
    }
  }, [value, previousValue]);

  if (typeof value !== 'number') {
    return <span className={className}>$--</span>;
  }

  const currentDigits = Math.round(value).toString().split('');
  const previousDigits = (previousValue || 0).toString().split('');
  
  // Pad arrays to same length
  while (currentDigits.length < previousDigits.length) currentDigits.unshift('0');
  while (previousDigits.length < currentDigits.length) previousDigits.unshift('0');

  return (
    <span className={`price-animate ${className}`}>
      $
      {currentDigits.map((digit, index) => (
        <AnimatedDigit
          key={index}
          digit={digit}
          previousDigit={previousDigits[index]}
          animate={!isFirstAnimation || (isFirstAnimation && value > 0)}
        />
      ))}
    </span>
  );
};

export default AnimatedPrice;