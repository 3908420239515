import React from 'react';
import { ChevronLeft } from 'lucide-react';
import logo from '../../../assets/icons/logo-white.svg';

export const EventHeader = ({ event, ticketInfo, onBack }) => {
  const formatDate = (date) => {
    if (!date || date === "TBD") return "TBD";
    return new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const formatTime = (time) => {
    if (!time || time === "TBD") return "TBD";
    try {
      const [hours, minutes] = time.split(":");
      const timeObj = new Date();
      timeObj.setHours(parseInt(hours), parseInt(minutes));
      return timeObj.toLocaleTimeString("en-US", {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    } catch (error) {
      console.error("Time formatting error:", error);
      return "Invalid Time";
    }
  };

  return (
    <div className="relative">
      <button 
        onClick={onBack} 
        className="absolute left-6 top-[23px] z-10"
      >
        <ChevronLeft size={24} />
      </button>

      <div className="pt-[21px] pb-4 text-center">
        <h1 className="text-[17px] font-bold">
          {event?.title?.split(' at ')[0]}
          <span className="text-[#999999]"> at </span>
          {event?.title?.split(' at ')[1]}
        </h1>
        <p className="text-[14px] font-medium">
          {event?.date ? formatDate(event.date) : "TBD"}
          {event?.time ? ` | ${formatTime(event.time)}` : ""}
        </p>
        {ticketInfo && (
          <p className="text-[13.13px] font-medium mt-2">
            {ticketInfo.ticketType === 'assigned' ? (
              `Sec ${ticketInfo.section} | Row ${ticketInfo.row} | Seat ${ticketInfo.seat}`
            ) : 'General Admission'}
          </p>
        )}
      </div>

      <div className="w-full h-[166px] relative">
        <img 
          src={event?.imageUrl} 
          alt={event?.title}
          className="w-full h-full object-cover"
        />
        <div 
          className="absolute bottom-0 right-0 w-[105px] h-[100px]"
          style={{
            clipPath: 'polygon(100% 0, 100% 100%, 0 100%)',
            backgroundColor: '#FF4C03'
          }}
        >
          <div className="absolute bottom-2 right-2">
            <img 
              src={logo} 
              alt="Logo" 
              className="w-[45.22px] h-[43.33px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};