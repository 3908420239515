// src/components/layout/AppLayout.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { FaBell, FaUserCircle } from "react-icons/fa";
import { BsTicketPerforatedFill } from "react-icons/bs";
import { apiConfig, getFetchConfig } from '../../config/api'; // Fix this path too

const AppLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const isActive = (path) => currentPath.startsWith(path);

  const hideNavBar = currentPath === '/' || 
    currentPath === '/login' || 
    currentPath === '/signup' || 
    currentPath === '/verify' || 
    currentPath === '/verification-success' || 
    currentPath === '/forgot-password' || 
    currentPath === '/unsupported-school' ||
    currentPath.startsWith('/admin');

  // In AppLayout.jsx

  const [activityCount, setActivityCount] = useState(0);

  useEffect(() => {
    const fetchActivityCount = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;
  
        const userResponse = await fetch(apiConfig.user.profile, getFetchConfig(token));
        const userData = await userResponse.json();
  
        // Fetch all events
        const eventsResponse = await fetch(
          apiConfig.events.list('Indiana University'),
          getFetchConfig(token)
        );
        const events = await eventsResponse.json();
  
        let totalActivities = 0;
        await Promise.all(events.map(async (event) => {
          const listingsResponse = await fetch(
            apiConfig.listings.getForEvent(event._id),
            getFetchConfig(token)
          );
          const { listings } = await listingsResponse.json();
  
          listings?.forEach(listing => {
            // Add debug logging with safe null checks
            console.log('Checking listing:', {
              listingId: listing._id,
              sellerId: listing.sellerId?._id, // Add optional chaining
              userId: userData._id,
              buyerId: listing.buyerId?._id,
              status: listing.status,
              isSeller: listing.sellerId && listing.sellerId._id === userData._id, // Add null check
              isBuyer: listing.buyerId?._id === userData._id
            });
  
            // Count if user is seller - Add null check
            if (listing.sellerId && listing.sellerId._id === userData._id && 
                ['active', 'sale_in_progress', 'transfer_initiated', 'ticket_sent'].includes(listing.status)) {
              totalActivities++;
              console.log('Added seller activity');
            }
            
            // Count if user is buyer - already using optional chaining
            if (listing.buyerId?._id === userData._id && 
                ['sale_in_progress', 'transfer_initiated', 'ticket_sent'].includes(listing.status)) {
              totalActivities++;
              console.log('Added buyer activity');
            }
          });
        }));
  
        console.log('Total activities:', totalActivities);
        setActivityCount(totalActivities);
      } catch (error) {
        console.error('Error fetching activity count:', error);
      }
    };
  
    fetchActivityCount();
  }, [location.pathname]);

  if (hideNavBar) return children;

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <main className="flex-1 pb-[55px]">
        {children}
      </main>

      <div className="fixed pt-2 bottom-0 left-0 right-0 h-[55px] bg-white border-t border-[#CDCDCD]">
        <div className="flex justify-between items-center h-full max-w-md mx-auto px-6">
          <button 
            onClick={() => navigate('/browse')}
            className="flex flex-col items-center"
          >
            <BsTicketPerforatedFill 
              size={28}
              className={isActive('/browse') ? 'text-[#FF4C03]' : 'text-black'} 
            />
            <span 
              className={`text-[13px] ${
                isActive('/browse') ? 'text-[#FF4C03]' : 'text-black'
              }`}
            >
              Tickets
            </span>
          </button>

          <button 
            onClick={() => navigate('/activity')}
            className="flex flex-col items-center relative"
          >
            <FaMoneyBillTransfer 
              size={28}
              className={isActive('/activity') ? 'text-[#FF4C03]' : 'text-black'} 
            />
            {activityCount > 0 && (
              <span className="absolute -top-1 -right-1 bg-[#FF4C03] text-white text-[10px] w-4 h-4 rounded-full flex items-center justify-center">
                {activityCount}
              </span>
            )}
            <span 
              className={`text-[13px] ${
                isActive('/activity') ? 'text-[#FF4C03]' : 'text-black'
              }`}
            >
              Activity
            </span>
          </button>

          <button 
            onClick={() => navigate('/alerts')}
            className="flex flex-col items-center"
          >
            <FaBell 
              size={28}
              className={isActive('/alerts') ? 'text-[#FF4C03]' : 'text-black'} 
            />
            <span 
              className={`text-[13px] ${
                isActive('/alerts') ? 'text-[#FF4C03]' : 'text-black'
              }`}
            >
              Alerts
            </span>
          </button>

          <button 
            onClick={() => navigate('/profile')}
            className="flex flex-col items-center"
          >
            <FaUserCircle 
              size={28}
              className={isActive('/profile') ? 'text-[#FF4C03]' : 'text-black'} 
            />
            <span 
              className={`text-[13px] ${
                isActive('/profile') ? 'text-[#FF4C03]' : 'text-black'
              }`}
            >
              Me
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;