// src/pages/admin/AdminLoginPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminLoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('https://sectionu-backend.onrender.com/api/auth/login', {       
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      if (formData.email !== 'broppenh@iu.edu') {
        throw new Error('Not authorized as admin');
      }

      // Store the token
      localStorage.setItem('token', data.token);
      
      // Navigate to admin dashboard
      navigate('/admin/dashboard');
    } catch (error) {
      setError(error.message || 'Failed to login');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white font-['Poppins'] p-6">
      {/* Status Bar Space */}
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />

      {/* Logo */}
      <div className="w-14 h-10 relative mb-12">
        <div className="w-[42.85px] h-[35.11px] left-[0.36px] top-[4.89px] absolute bg-[#FF4C03]" />
        <div className="w-[43.75px] h-[31.31px] left-[12.25px] top-0 absolute bg-[#FF4C03]" />
        <div className="w-[25.62px] h-[11.55px] left-0 top-[15.45px] absolute bg-[#FF4C03]" />
      </div>

      <div className="flex flex-col gap-[18px]">
        <h1 className="text-[27.70px] font-bold text-[#181619] leading-[27.70px]">
          Admin Panel
        </h1>
        <p className="text-[16px] text-gray-600">
          Login required for administrative access
        </p>

        {error && (
          <div className="p-4 bg-red-50 text-red-600 rounded-lg text-center">
            {error}
          </div>
        )}

        <form onSubmit={handleLogin} className="flex flex-col gap-[33px]">
          <div className="flex flex-col gap-[19px]">
            <input
              type="email"
              placeholder="Admin Email"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              className="px-[27.70px] py-[18.47px] rounded-[9.23px] border border-[#E1E1E1] text-[18.47px] text-[#7D7D7D] leading-[27.70px] focus:outline-none focus:border-[#FF4C03]"
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={(e) => setFormData({...formData, password: e.target.value})}
              className="px-[27.70px] py-[18.47px] rounded-[9.23px] border border-[#E1E1E1] text-[18.47px] text-[#7D7D7D] leading-[27.70px] focus:outline-none focus:border-[#FF4C03]"
              required
            />
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full py-[10.96px] bg-[#FF4C03] text-white text-[17.54px] font-bold leading-[26.31px] rounded-[109.63px] disabled:opacity-50"
          >
            {isLoading ? 'Logging in...' : 'Access Admin Panel'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginPage;