import React from 'react';
import ConfirmationFlow from './ConfirmationFlow';
import PurchaseComplete from './PurchaseComplete';
import WaitingForSeller from './WaitingForSeller';
import TransferInitiated from './TransferInitiated';
import TransactionComplete from '../shared/TransactionComplete';  // Add this import
import { TransferProgressBar } from '../../TransferProgressBar';

const BuyerView = ({ 
  listing, 
  event, 
  status, 
  onContinueBrowsing, 
  onViewAlerts,
  onConfirmReceipt 
}) => {
  const renderView = () => {
    console.log('Rendering BuyerView:', { status });

    switch (status) {
      case 'purchase_complete':
        return (
          <PurchaseComplete 
            event={event}
            listing={listing}
            onContinueBrowsing={onContinueBrowsing}
            onViewAlerts={onViewAlerts}
          />
        );
      
      case 'sale_in_progress':
        return (
          <WaitingForSeller
            event={event}
            listing={listing}
            onContinueBrowsing={onContinueBrowsing}
            onViewAlerts={onViewAlerts}
          />
        );
      
      case 'transfer_initiated':
        return (
          <TransferInitiated
            event={event}
            listing={listing}
            onContinueBrowsing={onContinueBrowsing}
            onViewAlerts={onViewAlerts}
          />
        );

      case 'ticket_sent':
        return (
          <ConfirmationFlow
            listingId={listing._id}
            eventId={event._id}
            onConfirm={onConfirmReceipt}
          />
        );

      case 'confirmed':
        return (
          <TransactionComplete 
            listing={listing}
            userType="buyer"
            onComplete={() => {
              // Here you can add any additional completion logic
              console.log('Buyer rating submitted');
            }}
          />
        );
      
      case 'completed':
        return (
          <PurchaseComplete
            event={event}
            listing={listing}
            onContinueBrowsing={onContinueBrowsing}
            onViewAlerts={onViewAlerts}
            showCompleteMessage={true}
          />
        );
      
      default:
        return (
          <PurchaseComplete
            event={event}
            listing={listing}
            onContinueBrowsing={onContinueBrowsing}
            onViewAlerts={onViewAlerts}
          />
        );
    }
  };

  return (
    <div className="flex flex-col items-center">
      {renderView()}
    </div>
  );
};

export default BuyerView;