// src/components/events/detail/VerificationBadge.jsx
import React from 'react';

export const VerificationBadge = () => {
  return (
    <div className="mx-6 my-4 p-4 border border-[#B7A8A8] rounded-lg flex items-center gap-2">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.667 9.16669V10C16.6655 11.7878 16.0534 13.5157 14.9276 14.9136C13.8019 16.3115 12.2327 17.2983 10.4775 17.7275C8.72237 18.1567 6.87634 18.0053 5.21333 17.295C3.55031 16.5846 2.15724 15.3503 1.26704 13.7702C0.376836 12.1901 0.0338106 10.3505 0.291788 8.53864C0.549765 6.72678 1.39561 5.03903 2.69269 3.74197C3.98977 2.44492 5.67753 1.5991 7.48939 1.34115C9.30125 1.0832 11.1408 1.42625 12.721 2.31669" stroke="#FF4C03" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.6667 3.33331L9.16666 10.8333L6.66666 8.33331" stroke="#FF4C03" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      <span className="text-[14px] font-semibold font-inter">
        SectionU Verification Guarantee
      </span>
    </div>
  );
};