// src/components/auth/AdminRoute.jsx
import { Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        if (!token) {
          setIsLoading(false);
          return;
        }

        const response = await fetch('https://sectionu-backend.onrender.com/api/user/profile', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const userData = await response.json();
        
        // Check if user is admin
        setIsAdmin(userData.email === 'broppenh@iu.edu');
      } catch (error) {
        setIsAdmin(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAdmin();
  }, [token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AdminRoute;