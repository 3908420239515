// src/components/events/detail/AssignedSeatingView.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const AssignedSeatingView = ({ event, listings }) => {
  const navigate = useNavigate();

  // Sort listings by price
  const sortedListings = [...listings].sort((a, b) => a.price - b.price);

  return (
    <div className="flex flex-col h-full">
      {/* Last Sale Section */}
      <div className="px-6 py-4 border-b border-[#CDCDCD]">
        <div className="flex justify-between items-center">
          <span className="text-[16px] font-medium">Last Sale</span>
          <span className="text-[#FF4C03] text-[24px] font-bold">$18</span>
        </div>
        <p className="text-[12px] text-[#909090] mt-1">
          {listings.length} tickets for sale
        </p>
      </div>

      {/* Ticket Listings */}
      <div className="flex-1 overflow-y-auto">
        {sortedListings.map((listing) => (
          <div 
            key={listing._id}
            onClick={() => navigate(`/event/${event._id}/buy`, { state: { listingId: listing._id } })}
            className="px-6 py-3 flex justify-between items-center border-b border-[#CDCDCD] cursor-pointer"
          >
            <div>
              <h3 className="text-[16px] font-bold">Student Ticket</h3>
              <p className="text-[13px] text-[#909090] font-medium">
                Sec {listing.section} | Row {listing.row} | Seat {listing.seat}
              </p>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-[#FF4C03] font-bold text-[22px]">
                ${listing.price}
              </span>
              <span className="text-[12px] text-[#909090]">
                Assigned Seat
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Floating Button - positioned above nav bar with shadow */}
      <div 
        className="fixed left-0 right-0 mx-6"
        style={{ 
          bottom: 'calc(env(safe-area-inset-bottom) + 80px)',
          filter: 'drop-shadow(0 0 0 rgba(0, 0, 0, 0.25))'
        }}
      >
        <button
          onClick={() => navigate(`/event/${event._id}/list`)}
          className="w-full py-3 bg-[#FF4C03] text-white text-[16px] font-bold rounded-full"
        >
          List your ticket
        </button>
      </div>
    </div>
  );
};