import React from 'react';
import { PaymentForm } from './PaymentForm';
import StripeWrapper from './StripeWrapper';
import { ChevronRight } from 'lucide-react';


export const PaymentMethodSelector = ({
  savedPaymentMethod,
  isPaymentFormExpanded,
  onPaymentMethodChange,
  onPaymentFormToggle,
  amount,
  eventId,
  listingId,
  onSuccess,
  onError
}) => {
  if (savedPaymentMethod) {
    return (
      <div className="mb-6">
        <div className="flex justify-between items-center p-4 border border-gray-300 rounded-lg">
          <div className="flex items-center gap-3">
            <div className="w-10 h-6 bg-gray-100 rounded flex items-center justify-center">
              {savedPaymentMethod.brand.toUpperCase()}
            </div>
            <span>•••• {savedPaymentMethod.last4}</span>
          </div>
          <button 
            onClick={() => {
              onPaymentMethodChange(null);
              onPaymentFormToggle(true);
            }}
            className="text-primary"
          >
            Change
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6">
      {isPaymentFormExpanded ? (
        <div className="animate-slide-down">
          <StripeWrapper amount={amount}>
            <PaymentForm 
              amount={amount}
              eventId={eventId}
              listingId={listingId}
              onPaymentSuccess={onSuccess}
              onPaymentError={onError}
            />
          </StripeWrapper>
          <button
            onClick={() => onPaymentFormToggle(false)}
            className="mt-4 w-full py-2 text-gray-500"
          >
            Cancel
          </button>
        </div>
      ) : (
        <button 
          onClick={() => onPaymentFormToggle(true)}
          className="w-full p-4 border border-gray-300 rounded-lg text-left flex justify-between items-center"
        >
          <span>Enter card details</span>

          <ChevronRight 
          size={20}
          />
        </button>
      )}
    </div>
  );
};

export default PaymentMethodSelector;