// src/pages/auth/UnsupportedSchoolPage.jsx
import { useNavigate } from 'react-router-dom';

const UnsupportedSchoolPage = () => {
  console.log('UnsupportedSchoolPage rendering'); // Add this
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-white flex flex-col">
      {/* Status Bar Space */}
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
      {/* Header */}
      <div className="flex justify-end px-6 py-4">
        <button 
          className="text-[#FF4C03] font-bold text-[17.73px]"
          onClick={() => navigate('/login')}
        >
          Log in
        </button>
      </div>

      {/* Content */}
      <div className="flex-1 flex flex-col justify-center items-center px-6">
        <div className="text-center space-y-4">
          <h2 className="text-2xl font-semibold text-black">Oops.</h2>
          <p className="text-black text-lg">
            SectionU is currently only available for College and University students at select schools in the US.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnsupportedSchoolPage;