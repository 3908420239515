// src/components/payment/StripeWrapper.jsx
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';

// Initialize Stripe outside of component
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const StripeWrapper = ({ children, amount }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    // Verify Stripe initialization
    if (!import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY) {
      console.error('Stripe key is missing');
      setError('Payment system configuration error');
    }
  }, []);

  // Log the environment for debugging
  console.log('Stripe environment:', {
    hasKey: !!import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    isDev: import.meta.env.DEV,
    mode: import.meta.env.MODE
  });

  const options = {
    mode: 'payment',
    amount: amount ? Math.round(amount * 100) : undefined,
    currency: 'usd',
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#FF4C03',
      },
    },
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default StripeWrapper;