// src/components/common/Loader.jsx
import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import loaderAnimation from '../../assets/loaders/loader.json';

const Loader = ({ showAfter = 300 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Start a timeout for showing the loader
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, showAfter);

    // If loading finishes early, clear the timeout so the loader doesn’t show
    return () => clearTimeout(timer);
  }, [showAfter]);

  if (!isVisible) return null;

  return (
    <div className="flex items-center justify-center" style={{ height: '100vh' }}>
      <Lottie animationData={loaderAnimation} style={{ width: '80px', height: '80px' }} />
    </div>
  );
};

export default Loader;