import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { EventHeader } from '../../components/events/listing/EventHeader';
import { TicketTypeSelector } from '../../components/events/listing/TicketTypeSelector';
import { ListingForm } from '../../components/events/listing/ListingForm';
import Loader from '../../components/common/Loader';
import { apiConfig, getFetchConfig } from '../../config/api';

export const ListingFormPage = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [ticketType, setTicketType] = useState('general');
  const [formData, setFormData] = useState({
    section: '',
    row: '',
    seat: '',
    price: ''
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await fetch(
          apiConfig.events.detail(eventId),
          getFetchConfig(token)
        );

        if (!response.ok) {
          throw new Error('Failed to fetch event');
        }

        const data = await response.json();
        setEvent(data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvent();
  }, [eventId, navigate]);

  if (isLoading) return <Loader />;

  // Validate if the price entered is greater than $1
  const isPriceValid = Number(formData.price) > 0;

  return (
    <div className="flex flex-col h-screen bg-white font-poppins">
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />

      <div className="flex-none">
        <EventHeader 
          event={event} 
          onBack={() => navigate('/browse')}
        />

        <div className="bg-[#FFE9DF] px-6 py-4">
          <h1 className="text-center text-[20.17px] font-semibold">
            List your ticket
          </h1>
          <p className="text-center text-[10px] leading-[12.12px] mt-2">
            Please double check your ticket type. Failure to list the correct ticket type may
            result in a voided sale.
          </p>
        </div>

        <TicketTypeSelector
          selected={ticketType}
          onSelect={setTicketType}
        />
      </div>

      <div className="flex-1 overflow-y-auto px-6 pt-6">
        <ListingForm
          ticketType={ticketType}
          formData={formData}
          onChange={(field, value) => setFormData(prev => ({ ...prev, [field]: value }))}
        />

        <button
          onClick={() => navigate(`/event/${eventId}/review`, { 
            state: {
              eventId,
              ticketType,
              section: formData.section,
              row: formData.row,
              seat: formData.seat,
              price: formData.price
            }
          })}
          className={`w-full py-4 bg-primary text-white rounded-full mt-6 text-[16px] font-bold mb-24 ${!isPriceValid ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={!isPriceValid}
        >
          Review Listing
        </button>
      </div>
    </div>
  );
};

export default ListingFormPage;