import React from 'react';
import { useNavigate } from 'react-router-dom';
import successIcon from '../../assets/icons/success-icon.svg';
import logo from '../../assets/icons/logo.svg'; // Importing the logo

const VerificationSuccessPage = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    // Navigate to browse page
    navigate('/browse');
  };

  return (
    <div className="fixed inset-0 bg-white flex flex-col font-['Poppins']">
      {/* Status Bar Space */}
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />
      
     {/* Header */}
     <div className="flex justify-between items-center px-6 py-4">
        <div className="w-14 h-10 relative">
          {/* Logo */}
      <div className="flex justify-left mb-12">
        <img src={logo} alt="Logo" className="w-14 h-auto" /> {/* Using the SVG logo */}
      </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 px-6 flex flex-col">
        <div className="mt-14 space-y-8">
          <h1 
            className="text-[29.60px] font-bold text-[#181619] leading-[26.31px]"
          >
            Your All Set
          </h1>
          <p 
            className="text-[16.44px] text-[#181619] leading-[26.31px]"
          >
            Enjoy SectionU!
          </p>
        </div>

        {/* Success Icon */}
        <div className="flex-1 flex justify-center items-center">
          <img 
            src={successIcon} 
            alt="Success" 
            className="w-[244.49px] h-[295.78px]"
          />
        </div>
      </div>

      {/* Continue Button */}
      <div 
        className="p-6" 
        style={{ paddingBottom: 'max(env(safe-area-inset-bottom) + 24px, 24px)' }}
      >
        <button
          onClick={handleContinue}
          className="w-full h-[60px] bg-[#FF4C03] text-white font-bold text-[17.54px] rounded-full"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default VerificationSuccessPage;