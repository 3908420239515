import React, { useRef } from 'react';

export const PriceInput = ({ value, onChange, placeholder }) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9.]/g, '');
    if (!/^\d*\.?\d*$/.test(newValue)) return;
    onChange(newValue);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="space-y-2">
      <label className="block text-[14px] font-medium text-black">
        Asking Price
      </label>
      <div className="relative w-full">
        <div className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[16px] font-normal">
          <span className={value ? 'text-black' : 'text-[#6B7280]'}>$</span>
        </div>
        <input
          ref={inputRef}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*\.?[0-9]*"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className="w-full h-14 pl-8 pr-4 text-[16px] font-normal text-black placeholder:text-[#6B7280] bg-white border-2 border-[#E5E7EB] rounded-lg focus:outline-none focus:border-primary"
        />
      </div>
    </div>
  );
};