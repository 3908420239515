// src/components/events/success/views/SellerView/index.jsx
import React, { useState, useEffect } from "react";
import { SaleInProgress } from "./SaleInProgress";
import { TransferInitiated } from "./TransferInitiated";
import { WaitingForConfirmation } from "./WaitingForConfirmation";
import TransactionComplete from "../shared/TransactionComplete"; // Add this import
import { useListingActions } from "../../../../../hooks/listings/useListingActions";
import successIcon from "../../../../../assets/icons/success-icon.svg";
import { apiConfig } from '../../../../../config/api';


const SellerView = ({
  listing,
  status,
  price,
  onNavigate,
  onInitiateTransfer,
}) => {
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { isDeleting, transferError, handleDelete, handleScreenshotUpload } =
    useListingActions(listing?._id);

  useEffect(() => {
    const fetchEvent = async () => {
      if (!listing?.eventId) return;
      
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(
          apiConfig.events.detail(listing.eventId),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.ok) throw new Error('Failed to fetch event');
        const eventData = await response.json();
        setEvent(eventData);
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvent();
  }, [listing?.eventId]);


  const renderActiveListing = () => (
    <div className="flex flex-col items-center pt-6">
      {/* Seller's price display with no fees */}
      <div className="w-full mb-6 space-y-3">
        <div className="flex justify-between items-center">
          <span className="text-base">List Price</span>
          <span className="text-base font-semibold">${price?.toFixed(2)}</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-base">Fees (None)</span>
          <span className="text-base">-$0.00</span>
        </div>
        <div className="flex justify-between items-center pt-2 border-t border-gray-200">
          <span className="text-lg font-semibold">You Receive</span>
          <span className="text-lg font-semibold text-[#15803D]">
            ${price?.toFixed(2)}
          </span>
        </div>
      </div>

      <div className="flex flex-col items-center gap-4 mb-8">
        <div className="relative w-24 h-24">
          <img src={successIcon} alt="Logo" className="w-full h-full" />
        </div>
        <h2 className="text-2xl font-semibold text-center">
          Listing Confirmed.
        </h2>
        <p className="text-sm text-center text-gray-700">
          Thank you! We will notify you once your listing has sold.
          <br />
          Change of plans? Delete your listing below.
        </p>
      </div>

      {showDeleteConfirm ? (
        <div className="w-full space-y-4">
          <div className="p-4 bg-red-50 text-[#BA0000] rounded-lg text-center">
            Are you sure you want to delete this listing? This action cannot be
            undone.
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => setShowDeleteConfirm(false)}
              className="flex-1 py-3 border border-[#BA0000] text-[#BA0000] rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                await handleDelete();
                onNavigate("/browse");
              }}
              className="flex-1 py-3 bg-[#BA0000] text-white rounded-lg"
              disabled={isDeleting}
            >
              Confirm Delete
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setShowDeleteConfirm(true)}
          className="w-full py-4 text-[#BA0000] font-bold text-base border-2 border-[#BA0000] rounded-full mb-8"
        >
          Delete Listing
        </button>
      )}
    </div>
  );

  const renderView = () => {
    switch (status) {
      case "active":
        return renderActiveListing();
      case "sale_in_progress":
        return <SaleInProgress onInitiateTransfer={onInitiateTransfer} />;
        case "transfer_initiated":
          if (!event) return <div>Loading...</div>;
          return (
            <TransferInitiated
              buyerInfo={listing.buyerId}
              event={event}  // Now passing the full event object
              listingId={listing._id}
              onScreenshotUpload={handleScreenshotUpload}
              transferError={transferError}
            />
          );
      case "ticket_sent":
        return <WaitingForConfirmation buyerInfo={listing?.buyerId} />;
      case "confirmed":
        return (
          <TransactionComplete
            listing={listing}
            userType="seller"
            onComplete={() => {
              // Here you can add any additional completion logic
              console.log("Seller rating submitted");
            }}
          />
        );
      default:
        return renderActiveListing();
    }
  };

  return <div className="w-full">{renderView()}</div>;
};

export default SellerView;
