// src/hooks/listings/useListingActions.js
import { useState } from 'react';
import { apiConfig } from '../../config/api';

export const useListingActions = (listingId) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [transferError, setTransferError] = useState('');

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const token = localStorage.getItem('token');
      const response = await fetch(apiConfig.listings.delete(listingId), {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to delete listing');
      return true;
    } catch (error) {
      console.error('Delete error:', error);
      return false;
    } finally {
      setIsDeleting(false);
    }
  };

  return {
    isDeleting,
    transferError,
    handleDelete,
    setTransferError
  };
};