// src/components/events/success/views/SellerView/WaitingForConfirmation.jsx
import { TransferProgressBar } from '../../TransferProgressBar';


export const WaitingForConfirmation = () => {
  return (
    <div className="flex flex-col items-center pt-6">
      <h2 className="text-2xl font-semibold text-center mb-4">
        Waiting for buyer to confirm receipt...
      </h2>
      
      <p className="text-center text-sm mb-8">
        Please allow the buyer around 10 minutes to confirm receipt of the transfer.
      </p>

      <TransferProgressBar status="ticket_sent" />

      <div className="w-full mt-8">
        <button
          className="w-full py-4 border border-[#FF4C03] text-[#FF4C03] rounded-full text-base font-medium"
        >
          Message Buyer
        </button>
        
        <button
          className="w-full mt-4 py-4 text-[#FF4C03] font-medium"
        >
          I need help
        </button>
      </div>
    </div>
  );
};