// src/components/events/detail/GeneralAdmissionView.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const GeneralAdmissionView = ({ event, listings }) => {
  const navigate = useNavigate();
  
  const lowestPriceListing = listings.length > 0 
    ? listings.reduce((lowest, current) => 
        current.price < lowest.price ? current : lowest
      )
    : null;

  return (
    <div className="flex flex-col items-center pb-40">
      {/* Stats */}
      <div className="flex flex-col items-center gap-4 py-3">
        <div className="text-center">
          <div className="text-[16.41px] font-medium">Available Tickets</div>
          <div className="text-[#FF4C03] text-[20px] font-semibold">
            {listings.length}
          </div>
        </div>
      </div>
{/* Lowest Price Display */}
<div className="text-center">
  <div className="text-[20px] font-medium">Lowest Listing</div>
  <div className="text-[50px] font-bold font-inter text-[#FF4C03]">
    ${lowestPriceListing ? lowestPriceListing.price.toFixed(0) : '--'}
  </div>
</div>

      {/* Action Buttons */}
      <div className="fixed bottom-0 left-0 right-0 p-6 space-y-4 bg-white border-t border-[#CDCDCD]">
        <button 
          onClick={() => lowestPriceListing && navigate(`/event/${event._id}/buy`, {
            state: { listingId: lowestPriceListing._id }
          })}
          className="w-full py-3 bg-[#FF4C03] text-white text-[16px] font-bold rounded-full disabled:opacity-50"
          disabled={!lowestPriceListing}
        >
          {lowestPriceListing 
            ? `Buy Now for $${Math.round(lowestPriceListing.price)}` 
            : 'No tickets available'
          }
        </button>
        <div className="flex items-center gap-4 justify-center text-[#12121199]">
          <div className="h-px flex-1 bg-current" />
          <span className="text-[16px] font-medium font-inter">or</span>
          <div className="h-px flex-1 bg-current" />
        </div>
        <button
          onClick={() => navigate(`/event/${event._id}/list`)}
          className="w-full py-3 bg-[#FFE9DF] text-[#FF4C03] text-[16px] font-bold rounded-full border-2 border-[#FF4C03]"
        >
          List Your Ticket
        </button>
        <div style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} />
      </div>
    </div>
  );
};