import React, { useState, useEffect } from 'react';
import { Plus, Pencil, Trash2, X } from 'lucide-react';
import { apiConfig, isDevelopment } from '../../config/api';

const AdminPanel = () => {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    time: '',
    sport: '',
    school: 'Indiana University',
    imageUrl: '/api/placeholder/400/320'
  });

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${apiConfig.admin.events}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch events');
      }

      const data = await response.json();
      setEvents(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching events:', error);
      alert('Failed to fetch events');
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submitData = {
        ...formData,
        imageUrl: formData.imageUrl || '/api/placeholder/400/320'
      };
  
      const url = selectedEvent 
        ? `${apiConfig.admin.events}/${selectedEvent._id}`
        : apiConfig.admin.events;
        
      const method = selectedEvent ? 'PUT' : 'POST';
      
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(submitData)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save event');
      }
  
      const data = await response.json();
      console.log('Event saved:', data);
  
      setIsModalOpen(false);
      setSelectedEvent(null);
      setFormData({
        title: '',
        date: '',
        time: '',
        sport: '',
        school: 'Indiana University',
        imageUrl: '/api/placeholder/400/320'
      });
      fetchEvents();
    } catch (error) {
      console.error('Error saving event:', error);
      alert(error.message);
    }
  };

  const handleDelete = async (eventId) => {
    if (!confirm('Are you sure you want to delete this event?')) return;
    
    try {
      const response = await fetch(`${apiConfig.admin.events}/${eventId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete event');
      }

      fetchEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
      alert('Failed to delete event');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Event Management</h1>
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center gap-2 bg-primary text-white px-4 py-2 rounded-lg"
          >
            <Plus size={20} />
            Add Event
          </button>
        </div>

        {/* Event List */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Event</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Date & Time</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Sport</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">School</th>
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {events.map(event => (
                <tr key={event._id}>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-4">
                      <img 
                        src={event.imageUrl} 
                        alt={event.title}
                        className="w-12 h-12 object-cover rounded"
                      />
                      <span className="font-medium">{event.title}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>{new Date(event.date).toLocaleDateString()}</div>
                    <div className="text-gray-500">{event.time}</div>
                  </td>
                  <td className="px-6 py-4">{event.sport}</td>
                  <td className="px-6 py-4">{event.school}</td>
                  <td className="px-6 py-4">
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setSelectedEvent(event);
                          setFormData(event);
                          setIsModalOpen(true);
                        }}
                        className="p-2 text-blue-600 hover:bg-blue-50 rounded"
                      >
                        <Pencil size={16} />
                      </button>
                      <button
                        onClick={() => handleDelete(event._id)}
                        className="p-2 text-red-600 hover:bg-red-50 rounded"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                {selectedEvent ? 'Edit Event' : 'Add New Event'}
              </h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setSelectedEvent(null);
                }}
                className="p-2"
              >
                <X size={20} />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Title</label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({...formData, title: e.target.value})}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Date</label>
                <input
                  type="date"
                  value={formData.date.split('T')[0]} // Handle date format
                  onChange={(e) => setFormData({...formData, date: e.target.value})}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>

              <div>
  <label className="block text-sm font-medium mb-1">Time</label>
  <input
    type="time"
    value={formData.time !== "TBD" ? formData.time : ""}
    onChange={(e) => setFormData({...formData, time: e.target.value})}
    className="w-full p-2 border rounded"
    disabled={formData.time === "TBD"} // Disable when "TBD" is selected
    required={formData.time !== "TBD"} // Make required only if "TBD" is not selected
  />
</div>

<div className="flex items-center mt-2">
  <input
    type="checkbox"
    id="tbd-checkbox"
    checked={formData.time === "TBD"}
    onChange={(e) => {
      setFormData({...formData, time: e.target.checked ? "TBD" : ""});
    }}
    className="mr-2"
  />
  <label htmlFor="tbd-checkbox" className="text-sm">TBD</label>
</div>

              <div>
                <label className="block text-sm font-medium mb-1">Sport</label>
                <select
                  value={formData.sport}
                  onChange={(e) => setFormData({...formData, sport: e.target.value})}
                  className="w-full p-2 border rounded"
                  required
                >
                  <option value="">Select a sport</option>
                  <option value="Football">Football</option>
                  <option value="Basketball">Basketball</option>
                  <option value="Baseball">Baseball</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div>
              <label className="block text-sm font-medium mb-1">Image URL</label>
                <input
                   type="text" // Change from url to text to accept the placeholder path
                   value={formData.imageUrl}
                    onChange={(e) => setFormData({...formData, imageUrl: e.target.value})}
                   className="w-full p-2 border rounded"
                   placeholder="/api/placeholder/400/320"
                />
               <p className="text-sm text-gray-500 mt-1">
                Use '/api/placeholder/400/320' for default placeholder image
              </p>
              </div>

              <button
                type="submit"
                className="w-full bg-primary text-white py-2 rounded-lg"
              >
                {selectedEvent ? 'Update Event' : 'Create Event'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;