// src/pages/alerts/AlertsPage.jsx
import React from 'react';

const AlertsPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white font-['Poppins']">
      <div className="flex-1 p-6">
        <h1 className="text-2xl font-bold">Alerts</h1>
        <p>Coming soon...</p>
      </div>
      <div className="flex flex-col min-h-screen bg-white font-['Poppins']">
      <div className="flex-1 p-6">
        <h1 className="text-2xl font-bold">Alerts</h1>
        <p>Coming soon...</p>
      </div>
      <div className="flex flex-col min-h-screen bg-white font-['Poppins']">
      <div className="flex-1 p-6">
        <h1 className="text-2xl font-bold">Alerts</h1>
        <p>Coming soon...</p>
      </div>
      <div className="flex flex-col min-h-screen bg-white font-['Poppins']">
      <div className="flex-1 p-6">
        <h1 className="text-2xl font-bold">Alerts</h1>
        <p>Coming soon...</p>
      </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default AlertsPage;