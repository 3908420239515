import React from 'react';

export const InputField = ({ label, value, onChange, placeholder, numeric = false }) => (
  <div className="space-y-2">
    <label className="block text-[14px] font-medium text-black">
      {label}
    </label>
    <input
      type="text"
      inputMode={numeric ? "numeric" : "text"}
      pattern={numeric ? "\\d*" : undefined}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full h-14 px-4 text-[16px] font-normal text-black placeholder:text-[#6B7280] bg-white border-2 border-[#E5E7EB] rounded-lg focus:outline-none focus:border-primary"
    />
  </div>
);