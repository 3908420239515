import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TransferProgressBar } from '../../TransferProgressBar';
import { calculateFees } from '../../../../../utils/feeCalculations';

const TransactionComplete = ({ 
  listing,
  userType, // 'buyer' or 'seller'
  onComplete
}) => {
  const [rating, setRating] = useState(0);
  const [showRatingUI, setShowRatingUI] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const fees = calculateFees(listing.price);

  // Check if listing has already been rated on component mount
  useEffect(() => {
    const hasBeenRated = listing.ratings?.[userType]?.score;
    setShowRatingUI(!hasBeenRated);
  }, [listing, userType]);

  const renderTransactionDetails = () => {
    if (userType === 'buyer') {
      return (
        <div className="w-full space-y-3 border border-gray-200 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <span className="text-base">Purchase Price</span>
            <span className="text-base font-semibold">${listing.price.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-base">Transaction Fee</span>
            <span className="text-base">+${fees.transactionFee.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-base">Processing Fee</span>
            <span className="text-base">+${fees.processingFee.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center pt-2 border-t border-gray-200">
            <span className="text-lg font-semibold">Total Paid</span>
            <span className="text-lg font-semibold text-[#FF4C03]">
              ${fees.total.toFixed(2)}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full space-y-3 border border-gray-200 rounded-lg p-4">
          <div className="flex justify-between items-center">
            <span className="text-base">Sale Price</span>
            <span className="text-base font-semibold">${listing.price.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-base">Platform Fee</span>
            <span className="text-base">-$0.00</span>
          </div>
          <div className="flex justify-between items-center pt-2 border-t border-gray-200">
            <span className="text-lg font-semibold">Total Received</span>
            <span className="text-lg font-semibold text-green-600">
              ${listing.price.toFixed(2)}
            </span>
          </div>
        </div>
      );
    }
  };

  const handleSubmitRating = async () => {
    try {
      setIsProcessing(true);
      const token = localStorage.getItem('token');

      // Submit rating
      await fetch(`/api/listings/${listing._id}/rate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rating,
          type: userType === 'buyer' ? 'seller' : 'buyer'
        })
      });

      // If this is the seller submitting rating, capture the payment
      if (userType === 'seller') {
        const captureResponse = await fetch(`/api/payments/capture-payment/${listing.paymentIntentId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!captureResponse.ok) {
          throw new Error('Failed to capture payment');
        }
      }

      if (onComplete) {
        onComplete();
      }

      setShowRatingUI(false);

    } catch (error) {
      console.error('Error completing transaction:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="w-full max-w-screen-lg">
      <h2 className="text-2xl font-semibold text-center">
        Transaction Complete
      </h2>
      
      <p className="text-sm text-center text-gray-500 mt-2 mb-6">
        ID: {listing._id}
      </p>

      <div className="w-full mb-8">
        <TransferProgressBar status="complete" />
      </div>

      {showRatingUI ? (
        <>
          <p className="text-center text-lg mt-1 mb-6">
            {userType === 'buyer' 
              ? 'Thank you! Please leave a rating for the seller.'
              : 'Thank you! Please leave a rating for the buyer to release your funds.'}
          </p>

          <div className="text-center mb-8 space-x-4">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onClick={() => setRating(star)}
                className={`text-5xl ${
                  star <= rating ? 'text-[#FF4C03]' : 'text-gray-300'
                }`}
              >
                ★
              </button>
            ))}
          </div>

          <button
            onClick={handleSubmitRating}
            disabled={!rating || isProcessing}
            className="w-full py-4 bg-[#FF4C03] text-white font-bold text-base rounded-full disabled:opacity-50"
          >
            {isProcessing ? 'Processing...' : 'Confirm'}
          </button>
        </>
      ) : (
        renderTransactionDetails()
      )}
    </div>
  );
};

export default TransactionComplete;