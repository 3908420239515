// src/config/api.js

// Environment detection
export const isDevelopment = import.meta.env.MODE === 'development';

// Base URLs configuration
const getBaseUrl = () => isDevelopment 
  ? '/api' 
  : 'https://sectionu-backend.onrender.com/api';

const getWsUrl = () => isDevelopment
  ? `ws://${window.location.hostname}:8000`
  : 'wss://sectionu-backend.onrender.com';

export const API_BASE_URL = getBaseUrl();
export const WS_BASE_URL = getWsUrl();

// API endpoints configuration
export const apiConfig = {
  auth: {
    login: `${API_BASE_URL}/auth/login`,
    signup: `${API_BASE_URL}/auth/signup`,
    verify: `${API_BASE_URL}/auth/verify`
  },
  events: {
    list: (school) => `${API_BASE_URL}/events/${school}`,
    detail: (id) => `${API_BASE_URL}/events/event/${id}`,
  },
  listings: {
    create: `${API_BASE_URL}/listings`,
    getForEvent: (eventId) => `${API_BASE_URL}/listings/event/${eventId}`,
    getUserListings: (userId) => `${API_BASE_URL}/listings/user/${userId}`,
    update: (listingId) => `${API_BASE_URL}/listings/${listingId}`,
    updateStatus: (listingId) => `${API_BASE_URL}/listings/${listingId}/status`,
    delete: (listingId) => `${API_BASE_URL}/listings/${listingId}`,
    uploadScreenshot: (listingId) => `${API_BASE_URL}/listings/${listingId}/transfer-screenshot`
  },
  admin: {
    events: `${API_BASE_URL}/admin/events`
  },
  user: {
    profile: `${API_BASE_URL}/user/profile`,
    update: `${API_BASE_URL}/user/profile`,
    delete: `${API_BASE_URL}/user/delete`
  },
  ocr: {
    verifyScreenshot: `${API_BASE_URL}/verify-screenshot`
  },
  payments: {
    createIntent: `${API_BASE_URL}/payments/create-payment-intent`,
    capturePayment: (paymentIntentId) => `${API_BASE_URL}/payments/capture-payment/${paymentIntentId}`
  }
};

// Enhanced fetch configuration with optional file upload support
export const getFetchConfig = (token, options = {}) => {
  const baseConfig = {
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
    credentials: isDevelopment ? 'same-origin' : 'include'
  };

  // If it's a file upload, don't set Content-Type (let browser set it with boundary)
  if (!options.isFileUpload) {
    baseConfig.headers['Content-Type'] = 'application/json';
  }

  return baseConfig;
};

// Enhanced API request helper with better error handling and logging
export const apiRequest = async (endpoint, options = {}) => {
  const token = localStorage.getItem('token');
  const config = getFetchConfig(token, options);
  const startTime = Date.now();

  try {
    // Log request in development
    if (isDevelopment) {
      console.log('API Request:', {
        endpoint,
        method: options.method || 'GET',
        headers: {
          ...config.headers,
          ...(options.headers || {})
        },
        body: options.body
      });
    }

    const response = await fetch(endpoint, {
      ...config,
      ...options,
      headers: {
        ...config.headers,
        ...(options.headers || {})
      }
    });

    const contentType = response.headers.get('content-type');
    const isJson = contentType?.includes('application/json');
    const responseData = isJson ? await response.json() : await response.text();

    // Log response timing in development
    if (isDevelopment) {
      console.log('API Response:', {
        endpoint,
        status: response.status,
        time: `${Date.now() - startTime}ms`,
        data: responseData
      });
    }

    if (!response.ok) {
      // Enhanced error handling
      const error = new Error(responseData.message || 'API request failed');
      error.status = response.status;
      error.data = responseData;
      throw error;
    }

    return responseData;
  } catch (error) {
    // Enhanced error logging
    console.error('API Error:', {
      endpoint,
      status: error.status,
      message: error.message,
      data: error.data,
      stack: isDevelopment ? error.stack : undefined,
      time: `${Date.now() - startTime}ms`
    });

    // If it's an authentication error, redirect to login
    if (error.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }

    throw error;
  }
};

// WebSocket message types
export const WS_MESSAGE_TYPES = {
  CONNECTION_ESTABLISHED: 'CONNECTION_ESTABLISHED',
  LISTING_UPDATE: 'LISTING_UPDATE',
  TRANSFER_UPDATE: 'TRANSFER_UPDATE'
};

// Helper function to create WebSocket URL with token
export const createWebSocketUrl = (token) => {
  const url = new URL(WS_BASE_URL);
  url.searchParams.append('token', token);
  return url.toString();
};

console.log('API Configuration:', {
  isDevelopment,
  API_BASE_URL,
  WS_BASE_URL,
  authEndpoints: {
    login: apiConfig.auth.login,
    signup: apiConfig.auth.signup
  }
});