import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import { apiConfig, isDevelopment } from '../../config/api';
import Loader from '../../components/common/Loader'; // Importing the Loader component
import logo from '../../assets/icons/logo.svg'; // Importing the logo

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // Check for remembered credentials on mount 
  useEffect(() => {
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      setFormData(prev => ({ ...prev, email: rememberedEmail }));
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch(apiConfig.auth.login, {       
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: isDevelopment ? 'same-origin' : 'include'
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      // Handle remember me
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', formData.email);
      } else {
        localStorage.removeItem('rememberedEmail');
      }

      // Store the token
      localStorage.setItem('token', data.token);
      
      // Navigate to the intended page or profile
      const intendedPath = location.state?.from?.pathname || '/browse';
      navigate(intendedPath, { replace: true });
    } catch (error) {
      setError(error.message || 'Failed to login');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />; // Show full-page loader when loading
  }

  return (
    <div className="min-h-screen bg-white font-['Poppins'] p-6">
      {/* Status Bar Space */}
      <div className="w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }} />

      {/* Logo */}
      <div className="flex justify-left mb-12">
        <img src={logo} alt="Logo" className="w-14 h-auto" /> {/* Using the SVG logo */}
      </div>

      {/* Login Form */}
      <div className="flex flex-col gap-[18px]">
        <h1 className="text-[27.70px] font-bold text-[#181619] leading-[27.70px]">
          Login
        </h1>

        {error && (
          <div className="p-4 bg-red-50 text-red-600 rounded-lg text-center">
            {error}
          </div>
        )}

        <form onSubmit={handleLogin} className="flex flex-col gap-[33px]">
          <div className="flex flex-col gap-[19px]">
            <div className="flex flex-col gap-[18px]">
              <input
                type="email"
                placeholder="School Email address"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                className="px-[27.70px] py-[18.47px] rounded-[9.23px] border border-[#E1E1E1] text-[18.47px] text-[#7D7D7D] leading-[27.70px] focus:outline-none focus:border-[#FF4C03]"
                required
              />
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password (8+ characters)"
                  value={formData.password}
                  onChange={(e) => setFormData({...formData, password: e.target.value})}
                  className="w-full px-[27.70px] py-[18.47px] rounded-[9.23px] border border-[#E1E1E1] text-[18.47px] text-[#7D7D7D] leading-[27.70px] focus:outline-none focus:border-[#FF4C03]"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                >
                  {showPassword ? 
                    <EyeOff size={20} /> : 
                    <Eye size={20} />
                  }
                </button>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <label className="flex items-center gap-2 text-[16px] text-[#7D7D7D]">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="w-4 h-4 rounded border-gray-300 text-[#FF4C03] focus:ring-[#FF4C03]"
                />
                Remember me
              </label>
              <button 
                type="button"
                onClick={() => navigate('/forgot-password')}
                className="text-[18.47px] text-[#BBBBBB] underline leading-[27.70px]"
              >
                Forgot password?
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-[37px] items-center">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-[10.96px] bg-[#FF4C03] text-white text-[17.54px] font-medium leading-[26.31px] rounded-[109.63px] disabled:opacity-50 flex items-center justify-center"
            >
              Log In
            </button>
            
            <button
              type="button"
              onClick={() => navigate('/signup')}
              className="text-[18.47px] text-[#FF4C03] font-bold leading-[27.70px]"
            >
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;